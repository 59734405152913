
h3 {
    background: blue;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}
.center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1.08);
}
.center h3 {
    opacity: 0.8;
    transition: all 300ms ease;
}
.content {
    padding: 20px;
    margin: auto;
    width: 90%;
}

.slick-slide .image {
    padding: 10px;
}
.slick-slide img {
    display: block;
}

.slick-dots {
    margin-left: 0;
}
.slick-thumb {
    bottom: -45px;
}
.slick-thumb li {
    width: 60px;
    height: 45px;
}

.slick-track {
    margin-left: 0 !important;
}

.slick-arrow {
    position: absolute;
    right: 1px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: 0 2px 4px 0 rgba(81, 81, 81, .2);
    background-color: #fff;
    border-radius: 50%;
    padding: 0;
    border: 0;
    z-index: 1;
}

.slick-arrow:hover {
    opacity: 1 !important;
}

.slick-arrow:before {
    content: '';
    width: 7px;
    height: 12px;
    position: absolute;
    transform: translate(-50%, -50%);
    background: url(../../../assets/svg/album-arrow-right.svg) center/cover no-repeat;
}

.slick-prev {
    left: 1px;
}

.slick-next:hover {
    background-color: #fff;
}

.slick-prev:hover {
    background-color: #fff;
}

.slick-prev:before {
    transform: scale(-1, 1) translate(50%, -50%) !important;
}

@media (max-width: 768px) {
    h3 {
        font-size: 1.5rem;
    }
    .center {
        margin-left: -40px;
        margin-right: -40px;
    }
    .center .slick-center h3 {
        color: #e67e22;
        opacity: 1;
        transform: scale(1);
    }
    .center h3 {
        opacity: 0.8;
        transform: scale(0.95);
        transition: all 300ms ease;
    }
}
.slick-vertical .slick-slide {
    height: 180px;
}

.slick-arrow:hover {
   opacity: 0.7;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    outline: none;
    background: #fff;
}