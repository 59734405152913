.css-1dkwqii {
    display: flex !important;
    justify-content: center !important;
    background-color: rgba(37, 99, 255, 0.35);
    position: relative !important;
}

.css-1dkwqii img {
    position: relative !important;
}

.css-nikas5 {
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.5) !important;
}

.css-ebdd77 {
    max-height: 60vh !important;
}