body {
  font-family: 'Proxima Nova', sans-serif;
  margin: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25;
  color: #4b4c59;
  background-color: #fcfcfd;
  overflow: hidden;
}

body .modal-open {
  overflow: hidden;
}

input {
  caret-color: #4a90e2;
}

html {
  font-size: 16px;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
}

@media (min-width: 1950px) {
  html {
    font-size: 18px
  }
}

@media (min-width: 2300px) {
  html {
    font-size: 20px
  }
}

@media (min-width: 2600px) {
  html {
    font-size: 22px
  }
}

@media (min-width: 2900px) {
  html {
    font-size: 24px
  }
}

@media (max-height: 580px) and (min-width: 768px) {
  html {
    font-size: 14px
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 14px
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 12px
  }
}

@media (max-width: 870px) {
  html {
    font-size: 10px
  }
}

*, ::after, ::before {
  box-sizing: inherit
}

html.popup-opened {
  overflow: hidden
}

@media (max-width: 767px) {
  html {
    font-size: 16px;
    background-color: #fff
  }

  body {
    background-color: #fff;
  }
}

@media (max-width: 480px) {
  body {
    font-size: .75rem
  }
}

code {
  font-family: monospace, monospace;
  font-size: 1em
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

main {
  display: block
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: transparent;
  color: #4b4c59;
}

abbr[title] {
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted
}

b, strong {
  font-weight: bolder
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -0.25em
}

sup {
  top: -0.5em
}

img {
  border-style: none
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button, input {
  overflow: visible
}

button, select {
  text-transform: none
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

@media (max-width: 767px) {
  input {
    font-size: 16px !important;
  }

  textarea {
    font-size: 16px !important;
  }
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details {
  display: block
}

summary {
  display: list-item
}

template {
  display: none
}

[hidden] {
  display: none;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  width: 82px;
  height: 82px;
}

.dropzone .dz-preview .dz-image {
  width: 82px !important;
  height: 82px !important;
}

div.filepicker {
  background-color: #ffffff !important;
}

.dropzone .dz-preview .dz-image {
  border-radius: 0 !important;
}

.filepicker.dropzone.dz-clickable {
  display: flex;
  position: relative;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  border: 0 !important;
  min-height: 241.5px;
  margin-bottom: 20px;
  background: url(/static/media/photo-upload-bg.79851420.svg) center/cover no-repeat
}

@media (max-width: 767px) {
  .filepicker.dropzone.dz-clickable {
    background: transparent !important;
  }
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
  font-weight: 600;
  font-size: inherit;
}

.dz-message {
  font-family: Proxima Nova, sans-serif;
  margin: 25px 0 0 50% !important;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.dropzone .dz-preview .dz-details {
  display: none;
}

.dropzone .dz-preview {
  margin: 3px !important;
  min-height: 80px !important;
}

.dropzone .dz-preview .dz-error-message {
  display: none !important;
}

.Crop--circle .ReactCrop__crop-selection {
  border-radius: 50%;
}

*, ::after, ::before {
  box-sizing: inherit
}

@media (max-width: 767px) {
  .ReactCrop {
    width: 100%;
  }
}

.react-switch-handle {
  position: absolute !important;
  top: 10% !important;
  left: 3px !important;
  width: 0.875rem !important;
  height: 0.875rem !important;
  border-radius: 50% !important;
}

.react-switch-bg {
  width: 2.125rem !important;
  height: 1.125rem !important;
  margin: 0 !important;
  border-radius: 9px !important;
  background-color: #edeff4 !important;
}

.css-16pqwjk-indicatorContainer {
  padding: 4px !important;
}

.css-1thkkgx-indicatorContainer {
  padding: 4px !important;
  color: hsl(0, 0%, 80%) !important;
}

.css-bg1rzq-control {
  border-radius: 0 !important;
  height: 1.875rem !important;
  min-height: 1.875rem !important;
}

.css-1szy77t-control {
  border-radius: 0 !important;
  height: 1.875rem !important;
  min-height: 1.875rem !important;
  border: 1px solid #dadee8 !important;
  box-shadow: none !important;
}

.css-1szy77t-control:hover {
  border: 1px solid #dadee8 !important;
  box-shadow: none !important;
}

.css-16pqwjk-indicatorContainer:hover {
  color: hsl(0, 0%, 80%) !important;
}

.react-slick__wrapper {
  width: 100%;
  position: relative;
  max-height: 6.5925rem;
}

.react-slick__wrapper .slick-list {
  overflow: visible;
}

.react-slick__wrapper .slick-slide {
  opacity: 0;
  -webkit-transition: opacity .3s;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}

.react-slick__wrapper .slick-slide.slick-active {
  opacity: 1;
}

@media (max-width: 767px) {
  .react-slick__wrapper {
    overflow: visible;
    max-height: 8rem;
    margin: 0;
    border: none;
  }
}

@media (max-width: 767px) {
  #_hj-f5b2a1eb-9b07_feedback[data-state="minimized"] {
    display: none;
  }

  iframe#launcher {
    bottom: 55px !important;
    right: -15px !important;
  }
}

#_hj-f5b2a1eb-9b07_feedback[data-viewmode="desktop"][data-minimized-position="middle_right"] #_hj-f5b2a1eb-9b07_feedback_minimized {
  bottom: 300px !important;
}

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label {
  background-color: #3b5a9a !important;
}

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label ._hj-f5b2a1eb-9b07_icon_emotion_default .path2:before {
  color: #3b5a9a !important;
}

.popperProfile-wrapper {
  position: relative;
  padding: 15px 11px 13px 15px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  z-index: 1;
}

.popperProfile-wrapper:before {
  position: absolute;
  right: 12px;
  top: -6px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
}

.popperProfile-icon {
  margin-right: 10px;
}

.popperProfile-description {
  font-size: 12px;
  line-height: 14px;
  color: #4B4C59;
}

.settings-wrapper {
  width: 100%;
  max-width: 40.3125rem;
}

@media (max-width: 768px) {
  .settings-wrapper {
    max-width: 100%;
  }
}

.settings-confirm-wrapper {
  display: flex;
  background-color: #EDEFF4;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 2px;
  padding: 24px 20px;
  margin-bottom: 20px;
}

.settings-confirm-icon {
  margin-right: 14px;
}

.settings-confirm-description p {
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: #4B4C59;
}

.blue-button {
  background-color: #3B5A9A;
  box-shadow: 0px 2px 4px rgba(221, 44, 87, 0.2);
  border-radius: 2px;
  min-width: 100px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

.credit-status {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid #DADEE8;
  box-sizing: border-box;
}

.credit-status.is-active {
  position: relative;
  border-color: #7ED321;
  background-color: #7ED321;
}

.credit-status.is-active:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  left: 5px;
  top: 1.5px;
  position: absolute;
  content: '';
}

.blue-button--profile {
  margin-left: 0;
}

.check-validate-icon {
  display: flex;
}

.check-validate-popper {
  position: absolute;
  z-index: 2;
  display: none;
  left: -5px;
  bottom: 22px;
  padding: 4px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #4B4C59;
  width: 150px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.5);
  border-radius: 2px;
  box-sizing: border-box;
  white-space: normal;
}

.check-validate-popper:before {
  position: absolute;
  bottom: -4px;
  left: 6px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
}

.check-validate {
  position: relative;
  margin-right: 6px;
}

.check-validate:hover .check-validate-popper {
  display: block;
}

.photo-wrapper {
  position: relative;
}

.blue-button--start {
  position: absolute;
  display: none;
  left: 50%;
  top: 85%;
  -webkit-transform: translate(-50%, -85%);
          transform: translate(-50%, -85%);
  z-index: 1;
  background-color: #4A90E2;
  margin-left: 0;
}

.photo-wrapper:hover .blue-button--start {
  display: block;
}

.modal-wrapper {
  width: 100%;
  max-width: 738px;
  margin: auto;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(184, 191, 211, 0.5);
  border-radius: 2px;
  padding: 90px 90px 100px;
}

.modal-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.207059px;
  color: rgba(51, 50, 56, 0.836589);
  margin-bottom: 32px;
}

.modal-description,
.modal-list-title {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.11px;
  color: rgba(35, 35, 39, 0.836589);
}

.modal-list-title {
  font-weight: bold;
}

.modal-list-item {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.11px;
  color: rgba(35, 35, 39, 0.836589);
  position: relative;
}

.modal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.modal-list-wrapper {
  margin-top: 40px;
}

.modal-list-item-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #DE60AD;
}

.modal-button {
  margin: 0 20px;
  padding: 18px 25px;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.11px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}

.modal-button--cancel {
  border: 1px solid #D3D3D3;
  background-color: #ffffff;
  color: rgba(139, 139, 139, 0.836589);
}

.modal-button--thank {
  background-color: #4A90E2;
  color: #ffffff;
}

.modal-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .modal-title {
    font-size: 24px;
    line-height: 26px;
  }

  .modal-wrapper {
    padding: 20px 20px 25px;
  }

  .catalog {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
}

.verify-email-wrapper {
  position: relative;
  overflow: hidden;
  margin: .78125rem;
  width: 11.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .verify-email-wrapper {
    width: calc(100% / 3 - .75rem);
    margin: .375rem;
  }
}

@media (max-width: 590px) {
  .verify-email-wrapper {
    width: calc(100% / 2 - .375rem);
    min-width: 8.75rem;
    margin: .1875rem;
  }
}
.verify-email {
  background-color: #EDEFF4;
  border-radius: 0.125rem;
  padding: 1.25rem 0.25rem 0.875rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verify-email__title {
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: #4B4C59;
  font-weight: 600;
}

.verify-email__description {
  font-size: 0.813rem;
  line-height: 1.25rem;
  color: #4B4C59
}

.verify-email__icon {
  margin: 0.75rem 0;
}

.verify-email__icon svg {
  width: 4.313rem;
  height: 3.25rem;
}

.verify-email__email {
  color: #4A90E2;
  display: block;
}

.verify-email-link {
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: #4B4C59;
  text-decoration: none;
  font-weight: 600;
  margin: 0 0.875rem;
  cursor: pointer;
}

.verify-email-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.verify-email-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  flex: 0 0 auto;
}

.verify-message {
  background-color: #EDEFF4;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 0.125rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
  position: relative;
}

@media (max-width: 767px) {
  .verify-message {
    padding: .625rem 1.5rem .625rem .625rem;
  }
}

.verify-message__close-btn {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  width: 0.813rem;
  height: 0.813rem;
  cursor: pointer;
}

.verify-message__close-btn:before,
.verify-message__close-btn:after {
  position: absolute;
  left: 0.313rem;
  top: 0;
  content: ' ';
  height: 0.875rem;
  width: 0.094rem;
  background-color: rgba(51, 50, 56, 0.524513);
}
.verify-message__close-btn:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.verify-message__close-btn:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.verify-message__description {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #4B4C59;
}

.verify-message__email {
  color: #4A90E2;
  font-weight: 600;
}

.verify-message__link {
  font-size: 0.938rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.verify-message__link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.verify-message__icon {
  display: flex;
  margin-right: 0.875rem;
  flex: 0 0 auto;
}

.verify-message__icon svg {
  width: 1.375rem;
  height: 1.375rem;
}

.notification-item {
  position: relative;
  background-color: #EBEFF5;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 20px 8px 16px 15px;
  display: flex;
  align-items: center;
}

.notification-item .notification-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #3B5A9A;
  margin-right: 5px;
  flex: 0 0 auto;
}

.notification-item .notification-image {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  flex: 0 0 auto;
  margin-right: 8px;
}

.notification-item .notification-image .notification-image__online-dot {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  background-color: #7ED321;
}

.notification-about .notification-about__title {
  font-size: 16px;
  line-height: 19px;
  color: #4B4C59;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px;
  white-space: nowrap;
}

.notification-about .notification-about__description {
  font-size: 12px;
  line-height: 14px;
  color: #4B4C59;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px;
  white-space: nowrap;
}

.notification-item .notification-button {
  padding: 0 15px;
  background-color: #4A90E2;
  box-shadow: 0px 2px 4px rgba(221, 44, 87, 0.2);
  border-radius: 2px;
  margin-top: 0;
  margin-left: auto;
  max-width: 100px;
}

.notification-close-button {
  width: 23px;
  height: 23px;
  background-color: #3B5A9A;
  position: absolute;
  right: -12px;
  top: -15px;
  border-radius: 100%;
}

.notification-close-button:before,
.notification-close-button:after {
  position: absolute;
  left: 10.5px;
  top: 4px;
  content: ' ';
  height: 15px;
  width: 1.5px;
  background-color: #EBEFF5;
}

.notification-close-button:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.notification-close-button:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .Toastify__toast-container {
    width: 100% !important;
  }

  .Toastify__toast-container--bottom-right {
    right: auto !important;
    right: initial !important;
    bottom: 5em !important;
  }

  .Toastify__toast {
    margin-bottom: 0 !important;
  }
}

.Toastify__toast-container--bottom-right {
  right: 4em !important;
}

.Toastify__toast--default {
  background: transparent !important;
  color: inherit !important;
}

.Toastify__toast {
  box-shadow: none !important;
  overflow: visible !important;
}


.collapse-header {
  padding: 0.875rem 7.5rem 0.875rem 4rem !important;
  height: 3.75rem;
}

@media (max-width: 767px) {
  .collapse-header {
    height: auto;
    padding: 0.875rem 3rem 0.875rem 3rem !important;
  }

  .collapse-header-item__arrow {
    right: -2rem;
  }
}

.collapse-header:focus {
  outline: none !important;
}

.collapse-header-item__description {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #4B4C59;
}

.collapse-header-item__icon {
  position: absolute;
  left: -2.375rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.collapse-header-item__icon svg {
  width: 1.375rem;
  height: 1.25rem;
}

.collapse-header-item__icon-count {
  font-size: 0.938rem;
  line-height: 1.125rem;
  color: #DE60AD;
  font-weight: 600;
}

.collapse-header-item {
  position: relative;
  display: flex;
  align-items: center;
}

.collapse-header-item__link {
  text-decoration: none;
  color: #3b5a9a;
  font-size: 0.875rem;
  font-weight: 600;
}

.rc-collapse {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0.125rem !important;
  margin: 0.094rem -1.25rem 1.875rem;
  border-top: 1px solid #EDEFF4 !important;
  padding-top: 1.031rem;
}

.rc-collapse-item {
  margin: 0 1.25rem !important;
  background-color: #F5F8FD !important;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2) !important;
}

.rc-collapse-item:focus {
  outline: none !important;
}


.collapse-header-item__link:hover {
  text-decoration: underline;
}

.collapse-header-item__arrow {
  position: absolute;
  right: -5.75rem;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-180deg);
          transform: translateY(-50%) rotate(-180deg);
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
}

.collapse-header-item__arrow svg {
  width: 1.188rem;
  height: 0.688rem;
}

@media (max-width: 767px) {
  .collapse-header-item__arrow {
    right: -2rem;
  }
}

.rc-collapse-item.rc-collapse-item-active .collapse-header-item__arrow{
  -webkit-transform: translateY(-50%) rotate(0);
          transform: translateY(-50%) rotate(0);
}

.collapse-item {
  border-top: 1px solid #EDEFF4;
}

.collapse-item__desription {
  padding: 0.875rem 7.5rem 0.875rem 4rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #4B4C59;
  background-color: #F5F8FD;
  height: 3.75rem;
}

@media (max-width: 767px) {
  .collapse-item__desription {
    height: auto;
    padding: 0.875rem 3rem 0.875rem 3rem;
  }
}

.collapse-item__link {
  text-decoration: none;
  color: #3b5a9a;
  font-size: 0.875rem;
  font-weight: 600;
}

.collapse-item__link:hover {
  text-decoration: underline;
}

.rc-collapse-content {
  padding: 0 !important;
}

.rc-collapse-content-box {
  margin: 0 !important;
}

.payment-card-wrapper {
  position: relative;
  width: 11.875rem;
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 1.875rem;
  margin-left: auto;
  margin-right: auto;
}

.payment-card-arrow {
  position: absolute;
  right: 0.938rem;
  top: 50%;
  height: 0.5rem;
  width: 0.5rem;
  margin-top: -0.25rem;
  border-style: solid;
  border-color: #3B5A9A;
  border-width: 0px 0.125rem 0.125rem 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.payment-card-wrapper .payment-card-title {
  font-size: 1.125rem;
  line-height: 1.313rem;
  letter-spacing: 0.07rem;
  color: #4B4C59;
  margin-bottom: 0.313rem;
  font-weight: 600;
}

.payment-card-wrapper .price-wrapper {
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.055rem;
  color: #4B4C59;
}

.payment-card-wrapper .price-wrapper span {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  color: #4B4C59;
}

.payment-card-wrapper .price-wrapper strong {
  color: #3B5A9A;
}

.payment-card-wrapper .payment-card-discount {
  position: absolute;
  left: -1.625rem;
  top: -1.125rem;
}

.payment-card-wrapper .payment-card-discount__number {
  position: absolute;
  top: 50%;
  white-space: pre-line;
  left: 50%;
  text-align: center;
  letter-spacing: 0.6px;
  line-height: 9px;
  font-size: 0.5rem;
  font-weight: bold;
  color: white;
  -webkit-transform: rotate(-10.88deg) translate(-40%, -75%);
          transform: rotate(-10.88deg) translate(-40%, -75%);
}

.payment-card-wrapper .payment-card-discount svg {
  width: 3.366rem;
  height: 3.366rem;
}

.payment-accordion {
  margin: 0 0 2.5rem !important;
  padding: 0 !important;
  border: none !important;
}

.payment-accordion .rc-collapse-content-box {
  margin-bottom: -1.563rem !important;
  padding-bottom: 0.438rem !important;
}

.payment-accordion:focus,
.payment-accordion:hover {
  outline: none !important;
}

.payment-accordion .rc-collapse-item {
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.payment-accordion .rc-collapse-item.rc-collapse-item-active .payment-card-arrow{
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  margin-top: 0;
}

.payment-accordion .collapse-header--payment {
  padding: 0 !important;
  background-color: transparent !important;
  width: 11.875rem;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.checkbox-wrapper {
  width: 16.563rem;
  text-align: left;
  display: block;
  position: relative;
  padding-left: 1.563rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #4B4C59;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 1.563rem;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -0.125rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #B8BFD3;
  border-radius: 0.125rem;
}

.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #B8BFD3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  left: 0.281rem;
  top: 0.125rem;
  width: 0.375rem;
  height: 0.563rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.payment-cards {
  display: flex;
  align-items: center;
  margin-top: 1.875rem;
}

.payment-cards .payment-cards__item {
  margin: 0 0.125rem;
}

.payment-cards .payment-cards__item svg {
  width: 2.313rem;
  height: 1.688rem;
}

.mobile-search-btn svg{
  margin-right: -7px;
}

.mobile-search-btn.active {
  position: relative;
}

.mobile-search-btn.active:before {
  content: '';
  position: absolute;
  right: 3px;
  top: 12px;
  width: 7px;
  height: 7px;
  background-color: #DE60AD;
  border-radius: 100%;
}

.count-wrapper {
  font-size: 16px;
  line-height: 19px;
  color: #3B5A9A;
  display: flex;
  align-items: center;
  margin-left: auto;
  text-decoration: none;
}

.count-wrapper svg {
  margin-left: 4px;
}

.textarea-wrapper {
  position: relative;
  width: 100%;
}

.textarea-wrapper .count-price-wrapper {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.count-price-wrapper {
  display: flex;
  align-items: center;
}

.count-price-wrapper.mailbox {
  display: flex;
}

.count-price-wrapper.desktop {
  display: none;
}

@media (min-width: 768px) {
  .count-price-wrapper {
    display: none;
  }

  .count-price-wrapper.desktop {
    display: flex;
    padding-right: 5px;
  }
}

.count-price-wrapper .count,
.count-price-wrapper .divider {
  font-size: 14px;
  line-height: 16px;
  color: #B8BFD3;
}

.count-price-wrapper .price {
  font-size: 14px;
  line-height: 16px;
  color: #3B5A9A;
  display: flex;
  align-items: center;
}

.count-price-wrapper .price svg {
  width: 14px;
  height: 16px;
  margin-left: 2px;
}

.album-price {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.album-price svg {
  width: 18px;
  height: 20px;
}

@media (min-width: 768px) {
  .album-price {
    display: none;
  }
}

.tabs-wrapper {
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid #DADEE8;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 0.5rem;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.tabs-wrapper .react-tabs__tab-list {
  order: 2;
  display: flex;
  flex-direction: column;
  width: 6.25rem;
  flex: 0 0 auto;
  margin-left: auto;
  border-radius: 0.5rem;
  border: none;
  border-left: 1px solid #DADEE8;
  border-top: 1px solid #DADEE8;
  border-bottom: 1px solid #DADEE8;
  margin-top: -1px;
  margin-bottom: -1px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.tabs-wrapper .react-tabs__tab {
  border: none;
  padding: 0.563rem 0.3rem;
  text-align: left;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #515151;
  font-weight: 600;
  display: flex;
  align-items: center;
  bottom: 0;
  border-radius: 5px;
}

.tabs-wrapper .react-tabs__tab:focus:after {
  display: none;
}

.tabs-wrapper .react-tabs__tab .top-item {
  margin-left: 0.313rem;
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.tabs-wrapper .react-tabs__tab.react-tabs__tab--selected {
  color: #3B5A9A;
}

.tabs-wrapper .react-tabs__tab-panel {
  padding: 0.75rem 0 0.75rem 1.125rem;
  display: none;
  align-items: center;
  margin-right: auto;
  width: 100%;
}

.tabs-wrapper .react-tabs__tab-panel.react-tabs__tab-panel--selected {
  display: flex;
}

.subscribe-plan-title {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3B5A9A;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.subscribe-plan-price {
  font-size: 2.125rem;
  line-height: 2.5rem;
  color: #3B5A9A;
  font-weight: 700;
  margin-bottom: 0.313rem;
}

.subscribe-plan {
  text-align: left;
}

.subscribe-plan-description {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #515151;
}

.subscribe-plan-description p {
  margin: 0 0 0.25rem;
  display: flex;
}

.subscribe-plan-description p:last-child {
  margin-bottom: 0;
}

.subscribe-plan-description svg {
  width: 0.625rem;
  height: 0.75rem;
  position: relative;
  top: auto;
  right: auto;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
}

.tabs-title {
  font-size: 1.5rem;
  line-height: 1.313rem;
  text-align: center;
  color: #3B5A9A;
  margin-top: 2.5rem;
  margin-bottom: 0.938rem;
}

.tabs-subtitle {
  font-size: 0.875rem;
  line-height: 0.938rem;
  text-align: center;
  color: #4B4C59;
  margin-bottom: 1.563rem;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.profile-catalog-wrapper {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  padding: 1.25rem;
  margin-top: 2rem;
  max-width: 48.575rem;
}

.profile-catalog-title {
  font-size: 1.125rem;
  line-height: 1.313rem;
  letter-spacing: 1.125px;
  color: #515151;
  margin-bottom: 1.063rem;
  font-weight: 600;
}

.profile-catalog-list {
  padding: 0;
  display: flex;
  margin: -.78125rem;
  list-style: none;
  flex-wrap: wrap;
}

.profile-catalog-photo-wrapper {
  position: relative;
}

.profile-catalog-photo-wrapper:hover .blue-button--start{
  display: block;
}

.profile-catalog-photo-wrapper a {
  text-decoration: none;
  display: block;
  position: relative;
}

.profile-catalog-photo-wrapper img{
  display: block;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;",serif;
}

@media (min-width: 768px) {
  .profile-catalog-photo-wrapper img {
    width: 14.3rem;
  }
}

.profile-catalog-list__item {
  position: relative;
  overflow: hidden;
  margin: .78125rem;
  border-radius: .125rem;
  background-color: #fff;
}

@media (max-width: 767px) {
  .profile-catalog-list__item {
    width: calc(100% / 3 - .75rem);
    margin: .375rem;
  }

  .profile-catalog-list {
    margin: -.375rem;
  }
}

@media (max-width: 590px) {
  .profile-catalog-list__item {
    width: calc(100% / 2 - .375rem);
    min-width: 8rem;
    margin: .1875rem;
  }

  .profile-catalog-list {
    margin: -.125rem;
  }
}

.profile-catalog-list__item:nth-child(4) {
  display: none;
}

.profile-catalog-status {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  max-width: 9.6875rem;
  height: 2.25rem;
  margin-left: .375rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: .05rem;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .profile-catalog-status {
    padding-right: 1.25rem;
  }
}

@media (max-width: 480px) {
  .profile-catalog-status {
    font-size: .75rem;
    height: 1.75rem;
  }
}

.profile-catalog-status-wrapper {
  text-decoration: none;
  display: block;
  position: relative;
}

.profile-catalog-status-online {
  font-size: 0;
  position: absolute;
  right: .8125rem;
  bottom: .8125rem;
  width: .625rem;
  height: .625rem;
  color: transparent;
  border-radius: 50%;
  background-color: #7ed321;
}

@media (max-width: 767px) {
  .profile-catalog-list__item:nth-child(4) {
    display: block;
  }

  .profile-catalog-status-online {
    right: .5625rem;
    bottom: .625rem;
    width: .5rem;
    height: .5rem;
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.mailbox-item .error-optionList {
  right: -90px !important;
}

.mailbox-item .error-optionList:before {
  right: auto !important;
  left: 8px !important;
}

.ReactModal__Body--open, .ReactModal__Html--open {
  overflow-y: visible;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/media/ProximaNova-Semibold.c8dbfaeb.woff2) format('woff2'),
        url(/static/media/ProximaNova-Semibold.3c4a5cda.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/media/ProximaNova-Regular.ecef0fae.woff2) format('woff2'),
        url(/static/media/ProximaNova-Regular.a8ea564a.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url(/static/media/ProximaNova-Bold.571f1669.woff2) format('woff2'),
        url(/static/media/ProximaNova-Bold.637c241b.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}
.css-1dkwqii {
    display: flex !important;
    justify-content: center !important;
    background-color: rgba(37, 99, 255, 0.35);
    position: relative !important;
}

.css-1dkwqii img {
    position: relative !important;
}

.css-nikas5 {
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.5) !important;
}

.css-ebdd77 {
    max-height: 60vh !important;
}
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-abacus:before {
  content: "\F640"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-acorn:before {
  content: "\F6AE"; }

.fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.fa-ad:before {
  content: "\F641"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adobe:before {
  content: "\F778"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-air-freshener:before {
  content: "\F5D0"; }

.fa-alarm-clock:before {
  content: "\F34E"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-alicorn:before {
  content: "\F6B0"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-alipay:before {
  content: "\F642"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-analytics:before {
  content: "\F643"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angel:before {
  content: "\F779"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angry:before {
  content: "\F556"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-ankh:before {
  content: "\F644"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-alt:before {
  content: "\F5D1"; }

.fa-apple-crate:before {
  content: "\F6B1"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-archway:before {
  content: "\F557"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-alt-down:before {
  content: "\F354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.fa-arrow-alt-from-left:before {
  content: "\F347"; }

.fa-arrow-alt-from-right:before {
  content: "\F348"; }

.fa-arrow-alt-from-top:before {
  content: "\F349"; }

.fa-arrow-alt-left:before {
  content: "\F355"; }

.fa-arrow-alt-right:before {
  content: "\F356"; }

.fa-arrow-alt-square-down:before {
  content: "\F350"; }

.fa-arrow-alt-square-left:before {
  content: "\F351"; }

.fa-arrow-alt-square-right:before {
  content: "\F352"; }

.fa-arrow-alt-square-up:before {
  content: "\F353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.fa-arrow-alt-up:before {
  content: "\F357"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-from-bottom:before {
  content: "\F342"; }

.fa-arrow-from-left:before {
  content: "\F343"; }

.fa-arrow-from-right:before {
  content: "\F344"; }

.fa-arrow-from-top:before {
  content: "\F345"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-square-down:before {
  content: "\F339"; }

.fa-arrow-square-left:before {
  content: "\F33A"; }

.fa-arrow-square-right:before {
  content: "\F33B"; }

.fa-arrow-square-up:before {
  content: "\F33C"; }

.fa-arrow-to-bottom:before {
  content: "\F33D"; }

.fa-arrow-to-left:before {
  content: "\F33E"; }

.fa-arrow-to-right:before {
  content: "\F340"; }

.fa-arrow-to-top:before {
  content: "\F341"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows:before {
  content: "\F047"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-arrows-h:before {
  content: "\F07E"; }

.fa-arrows-v:before {
  content: "\F07D"; }

.fa-artstation:before {
  content: "\F77A"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-atlas:before {
  content: "\F558"; }

.fa-atlassian:before {
  content: "\F77B"; }

.fa-atom:before {
  content: "\F5D2"; }

.fa-atom-alt:before {
  content: "\F5D3"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-award:before {
  content: "\F559"; }

.fa-aws:before {
  content: "\F375"; }

.fa-axe:before {
  content: "\F6B2"; }

.fa-axe-battle:before {
  content: "\F6B3"; }

.fa-baby:before {
  content: "\F77C"; }

.fa-baby-carriage:before {
  content: "\F77D"; }

.fa-backpack:before {
  content: "\F5D4"; }

.fa-backspace:before {
  content: "\F55A"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-badge:before {
  content: "\F335"; }

.fa-badge-check:before {
  content: "\F336"; }

.fa-badge-dollar:before {
  content: "\F645"; }

.fa-badge-percent:before {
  content: "\F646"; }

.fa-badger-honey:before {
  content: "\F6B4"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-balance-scale-left:before {
  content: "\F515"; }

.fa-balance-scale-right:before {
  content: "\F516"; }

.fa-ball-pile:before {
  content: "\F77E"; }

.fa-ballot:before {
  content: "\F732"; }

.fa-ballot-check:before {
  content: "\F733"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-barcode-alt:before {
  content: "\F463"; }

.fa-barcode-read:before {
  content: "\F464"; }

.fa-barcode-scan:before {
  content: "\F465"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball:before {
  content: "\F432"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-basketball-hoop:before {
  content: "\F435"; }

.fa-bat:before {
  content: "\F6B5"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-bolt:before {
  content: "\F376"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-slash:before {
  content: "\F377"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-bed:before {
  content: "\F236"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-school:before {
  content: "\F5D5"; }

.fa-bell-school-slash:before {
  content: "\F5D6"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bells:before {
  content: "\F77F"; }

.fa-bezier-curve:before {
  content: "\F55B"; }

.fa-bible:before {
  content: "\F647"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-biohazard:before {
  content: "\F780"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blanket:before {
  content: "\F498"; }

.fa-blender:before {
  content: "\F517"; }

.fa-blender-phone:before {
  content: "\F6B6"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blog:before {
  content: "\F781"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-bone:before {
  content: "\F5D7"; }

.fa-bone-break:before {
  content: "\F5D8"; }

.fa-bong:before {
  content: "\F55C"; }

.fa-book:before {
  content: "\F02D"; }

.fa-book-alt:before {
  content: "\F5D9"; }

.fa-book-dead:before {
  content: "\F6B7"; }

.fa-book-heart:before {
  content: "\F499"; }

.fa-book-open:before {
  content: "\F518"; }

.fa-book-reader:before {
  content: "\F5DA"; }

.fa-book-spells:before {
  content: "\F6B8"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-books:before {
  content: "\F5DB"; }

.fa-boot:before {
  content: "\F782"; }

.fa-booth-curtain:before {
  content: "\F734"; }

.fa-bow-arrow:before {
  content: "\F6B9"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-bowling-pins:before {
  content: "\F437"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-alt:before {
  content: "\F49A"; }

.fa-box-ballot:before {
  content: "\F735"; }

.fa-box-check:before {
  content: "\F467"; }

.fa-box-fragile:before {
  content: "\F49B"; }

.fa-box-full:before {
  content: "\F49C"; }

.fa-box-heart:before {
  content: "\F49D"; }

.fa-box-open:before {
  content: "\F49E"; }

.fa-box-up:before {
  content: "\F49F"; }

.fa-box-usd:before {
  content: "\F4A0"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-boxes-alt:before {
  content: "\F4A1"; }

.fa-boxing-glove:before {
  content: "\F438"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-brain:before {
  content: "\F5DC"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-broadcast-tower:before {
  content: "\F519"; }

.fa-broom:before {
  content: "\F51A"; }

.fa-browser:before {
  content: "\F37E"; }

.fa-brush:before {
  content: "\F55D"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-bullseye-arrow:before {
  content: "\F648"; }

.fa-bullseye-pointer:before {
  content: "\F649"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-bus:before {
  content: "\F207"; }

.fa-bus-alt:before {
  content: "\F55E"; }

.fa-bus-school:before {
  content: "\F5DD"; }

.fa-business-time:before {
  content: "\F64A"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-cabinet-filing:before {
  content: "\F64B"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calculator-alt:before {
  content: "\F64C"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-day:before {
  content: "\F783"; }

.fa-calendar-edit:before {
  content: "\F333"; }

.fa-calendar-exclamation:before {
  content: "\F334"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-star:before {
  content: "\F736"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-calendar-week:before {
  content: "\F784"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-alt:before {
  content: "\F332"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-campfire:before {
  content: "\F6BA"; }

.fa-campground:before {
  content: "\F6BB"; }

.fa-canadian-maple-leaf:before {
  content: "\F785"; }

.fa-candle-holder:before {
  content: "\F6BC"; }

.fa-candy-cane:before {
  content: "\F786"; }

.fa-candy-corn:before {
  content: "\F6BD"; }

.fa-cannabis:before {
  content: "\F55F"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-car-alt:before {
  content: "\F5DE"; }

.fa-car-battery:before {
  content: "\F5DF"; }

.fa-car-bump:before {
  content: "\F5E0"; }

.fa-car-crash:before {
  content: "\F5E1"; }

.fa-car-garage:before {
  content: "\F5E2"; }

.fa-car-mechanic:before {
  content: "\F5E3"; }

.fa-car-side:before {
  content: "\F5E4"; }

.fa-car-tilt:before {
  content: "\F5E5"; }

.fa-car-wash:before {
  content: "\F5E6"; }

.fa-caret-circle-down:before {
  content: "\F32D"; }

.fa-caret-circle-left:before {
  content: "\F32E"; }

.fa-caret-circle-right:before {
  content: "\F330"; }

.fa-caret-circle-up:before {
  content: "\F331"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-carrot:before {
  content: "\F787"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cash-register:before {
  content: "\F788"; }

.fa-cat:before {
  content: "\F6BE"; }

.fa-cauldron:before {
  content: "\F6BF"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-centos:before {
  content: "\F789"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chair:before {
  content: "\F6C0"; }

.fa-chair-office:before {
  content: "\F6C1"; }

.fa-chalkboard:before {
  content: "\F51B"; }

.fa-chalkboard-teacher:before {
  content: "\F51C"; }

.fa-charging-station:before {
  content: "\F5E7"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-line-down:before {
  content: "\F64D"; }

.fa-chart-network:before {
  content: "\F78A"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-chart-pie-alt:before {
  content: "\F64E"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-double:before {
  content: "\F560"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-bishop-alt:before {
  content: "\F43B"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-clock:before {
  content: "\F43D"; }

.fa-chess-clock-alt:before {
  content: "\F43E"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-king-alt:before {
  content: "\F440"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-knight-alt:before {
  content: "\F442"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-pawn-alt:before {
  content: "\F444"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-queen-alt:before {
  content: "\F446"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chess-rook-alt:before {
  content: "\F448"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-double-down:before {
  content: "\F322"; }

.fa-chevron-double-left:before {
  content: "\F323"; }

.fa-chevron-double-right:before {
  content: "\F324"; }

.fa-chevron-double-up:before {
  content: "\F325"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-square-down:before {
  content: "\F329"; }

.fa-chevron-square-left:before {
  content: "\F32A"; }

.fa-chevron-square-right:before {
  content: "\F32B"; }

.fa-chevron-square-up:before {
  content: "\F32C"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chimney:before {
  content: "\F78B"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-church:before {
  content: "\F51D"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-city:before {
  content: "\F64F"; }

.fa-claw-marks:before {
  content: "\F6C2"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clipboard-list-check:before {
  content: "\F737"; }

.fa-clipboard-prescription:before {
  content: "\F5E8"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download:before {
  content: "\F0ED"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-drizzle:before {
  content: "\F738"; }

.fa-cloud-hail:before {
  content: "\F739"; }

.fa-cloud-hail-mixed:before {
  content: "\F73A"; }

.fa-cloud-meatball:before {
  content: "\F73B"; }

.fa-cloud-moon:before {
  content: "\F6C3"; }

.fa-cloud-moon-rain:before {
  content: "\F73C"; }

.fa-cloud-rain:before {
  content: "\F73D"; }

.fa-cloud-rainbow:before {
  content: "\F73E"; }

.fa-cloud-showers:before {
  content: "\F73F"; }

.fa-cloud-showers-heavy:before {
  content: "\F740"; }

.fa-cloud-sleet:before {
  content: "\F741"; }

.fa-cloud-snow:before {
  content: "\F742"; }

.fa-cloud-sun:before {
  content: "\F6C4"; }

.fa-cloud-sun-rain:before {
  content: "\F743"; }

.fa-cloud-upload:before {
  content: "\F0EE"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-clouds:before {
  content: "\F744"; }

.fa-clouds-moon:before {
  content: "\F745"; }

.fa-clouds-sun:before {
  content: "\F746"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-club:before {
  content: "\F327"; }

.fa-cocktail:before {
  content: "\F561"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-code-commit:before {
  content: "\F386"; }

.fa-code-merge:before {
  content: "\F387"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-coffee-togo:before {
  content: "\F6C5"; }

.fa-coffin:before {
  content: "\F6C6"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-coins:before {
  content: "\F51E"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comment-alt-check:before {
  content: "\F4A2"; }

.fa-comment-alt-dollar:before {
  content: "\F650"; }

.fa-comment-alt-dots:before {
  content: "\F4A3"; }

.fa-comment-alt-edit:before {
  content: "\F4A4"; }

.fa-comment-alt-exclamation:before {
  content: "\F4A5"; }

.fa-comment-alt-lines:before {
  content: "\F4A6"; }

.fa-comment-alt-minus:before {
  content: "\F4A7"; }

.fa-comment-alt-plus:before {
  content: "\F4A8"; }

.fa-comment-alt-slash:before {
  content: "\F4A9"; }

.fa-comment-alt-smile:before {
  content: "\F4AA"; }

.fa-comment-alt-times:before {
  content: "\F4AB"; }

.fa-comment-check:before {
  content: "\F4AC"; }

.fa-comment-dollar:before {
  content: "\F651"; }

.fa-comment-dots:before {
  content: "\F4AD"; }

.fa-comment-edit:before {
  content: "\F4AE"; }

.fa-comment-exclamation:before {
  content: "\F4AF"; }

.fa-comment-lines:before {
  content: "\F4B0"; }

.fa-comment-minus:before {
  content: "\F4B1"; }

.fa-comment-plus:before {
  content: "\F4B2"; }

.fa-comment-slash:before {
  content: "\F4B3"; }

.fa-comment-smile:before {
  content: "\F4B4"; }

.fa-comment-times:before {
  content: "\F4B5"; }

.fa-comments:before {
  content: "\F086"; }

.fa-comments-alt:before {
  content: "\F4B6"; }

.fa-comments-alt-dollar:before {
  content: "\F652"; }

.fa-comments-dollar:before {
  content: "\F653"; }

.fa-compact-disc:before {
  content: "\F51F"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compass-slash:before {
  content: "\F5E9"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-alt:before {
  content: "\F422"; }

.fa-compress-arrows-alt:before {
  content: "\F78C"; }

.fa-compress-wide:before {
  content: "\F326"; }

.fa-concierge-bell:before {
  content: "\F562"; }

.fa-confluence:before {
  content: "\F78D"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa-container-storage:before {
  content: "\F4B7"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-conveyor-belt:before {
  content: "\F46E"; }

.fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.fa-cookie:before {
  content: "\F563"; }

.fa-cookie-bite:before {
  content: "\F564"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-corn:before {
  content: "\F6C7"; }

.fa-couch:before {
  content: "\F4B8"; }

.fa-cow:before {
  content: "\F6C8"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-creative-commons-by:before {
  content: "\F4E7"; }

.fa-creative-commons-nc:before {
  content: "\F4E8"; }

.fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.fa-creative-commons-nd:before {
  content: "\F4EB"; }

.fa-creative-commons-pd:before {
  content: "\F4EC"; }

.fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.fa-creative-commons-remix:before {
  content: "\F4EE"; }

.fa-creative-commons-sa:before {
  content: "\F4EF"; }

.fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.fa-creative-commons-share:before {
  content: "\F4F2"; }

.fa-creative-commons-zero:before {
  content: "\F4F3"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-credit-card-blank:before {
  content: "\F389"; }

.fa-credit-card-front:before {
  content: "\F38A"; }

.fa-cricket:before {
  content: "\F449"; }

.fa-critical-role:before {
  content: "\F6C9"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crop-alt:before {
  content: "\F565"; }

.fa-cross:before {
  content: "\F654"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-crow:before {
  content: "\F520"; }

.fa-crown:before {
  content: "\F521"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-curling:before {
  content: "\F44A"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.fa-dagger:before {
  content: "\F6CB"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-deer:before {
  content: "\F78E"; }

.fa-deer-rudolph:before {
  content: "\F78F"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-democrat:before {
  content: "\F747"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-desktop-alt:before {
  content: "\F390"; }

.fa-dev:before {
  content: "\F6CC"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-dewpoint:before {
  content: "\F748"; }

.fa-dharmachakra:before {
  content: "\F655"; }

.fa-dhl:before {
  content: "\F790"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diamond:before {
  content: "\F219"; }

.fa-diaspora:before {
  content: "\F791"; }

.fa-dice:before {
  content: "\F522"; }

.fa-dice-d10:before {
  content: "\F6CD"; }

.fa-dice-d12:before {
  content: "\F6CE"; }

.fa-dice-d20:before {
  content: "\F6CF"; }

.fa-dice-d4:before {
  content: "\F6D0"; }

.fa-dice-d6:before {
  content: "\F6D1"; }

.fa-dice-d8:before {
  content: "\F6D2"; }

.fa-dice-five:before {
  content: "\F523"; }

.fa-dice-four:before {
  content: "\F524"; }

.fa-dice-one:before {
  content: "\F525"; }

.fa-dice-six:before {
  content: "\F526"; }

.fa-dice-three:before {
  content: "\F527"; }

.fa-dice-two:before {
  content: "\F528"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-digital-tachograph:before {
  content: "\F566"; }

.fa-diploma:before {
  content: "\F5EA"; }

.fa-directions:before {
  content: "\F5EB"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-divide:before {
  content: "\F529"; }

.fa-dizzy:before {
  content: "\F567"; }

.fa-dna:before {
  content: "\F471"; }

.fa-do-not-enter:before {
  content: "\F5EC"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dog:before {
  content: "\F6D3"; }

.fa-dog-leashed:before {
  content: "\F6D4"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-empty:before {
  content: "\F473"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.fa-donate:before {
  content: "\F4B9"; }

.fa-door-closed:before {
  content: "\F52A"; }

.fa-door-open:before {
  content: "\F52B"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-dove:before {
  content: "\F4BA"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-drafting-compass:before {
  content: "\F568"; }

.fa-dragon:before {
  content: "\F6D5"; }

.fa-draw-circle:before {
  content: "\F5ED"; }

.fa-draw-polygon:before {
  content: "\F5EE"; }

.fa-draw-square:before {
  content: "\F5EF"; }

.fa-dreidel:before {
  content: "\F792"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drum:before {
  content: "\F569"; }

.fa-drum-steelpan:before {
  content: "\F56A"; }

.fa-drumstick:before {
  content: "\F6D6"; }

.fa-drumstick-bite:before {
  content: "\F6D7"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-duck:before {
  content: "\F6D8"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dumpster:before {
  content: "\F793"; }

.fa-dumpster-fire:before {
  content: "\F794"; }

.fa-dungeon:before {
  content: "\F6D9"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-ear:before {
  content: "\F5F0"; }

.fa-ear-muffs:before {
  content: "\F795"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-ebay:before {
  content: "\F4F4"; }

.fa-eclipse:before {
  content: "\F749"; }

.fa-eclipse-alt:before {
  content: "\F74A"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edit:before {
  content: "\F044"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-elephant:before {
  content: "\F6DA"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.fa-ello:before {
  content: "\F5F1"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-empty-set:before {
  content: "\F656"; }

.fa-engine-warning:before {
  content: "\F5F2"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-open-dollar:before {
  content: "\F657"; }

.fa-envelope-open-text:before {
  content: "\F658"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-equals:before {
  content: "\F52C"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-ethernet:before {
  content: "\F796"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-exchange:before {
  content: "\F0EC"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-square:before {
  content: "\F321"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-alt:before {
  content: "\F424"; }

.fa-expand-arrows:before {
  content: "\F31D"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expand-wide:before {
  content: "\F320"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link:before {
  content: "\F08E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square:before {
  content: "\F14C"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-evil:before {
  content: "\F6DB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-feather:before {
  content: "\F52D"; }

.fa-feather-alt:before {
  content: "\F56B"; }

.fa-fedex:before {
  content: "\F797"; }

.fa-fedora:before {
  content: "\F798"; }

.fa-female:before {
  content: "\F182"; }

.fa-field-hockey:before {
  content: "\F44C"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-figma:before {
  content: "\F799"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-certificate:before {
  content: "\F5F3"; }

.fa-file-chart-line:before {
  content: "\F659"; }

.fa-file-chart-pie:before {
  content: "\F65A"; }

.fa-file-check:before {
  content: "\F316"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-contract:before {
  content: "\F56C"; }

.fa-file-csv:before {
  content: "\F6DD"; }

.fa-file-download:before {
  content: "\F56D"; }

.fa-file-edit:before {
  content: "\F31C"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-exclamation:before {
  content: "\F31A"; }

.fa-file-export:before {
  content: "\F56E"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-import:before {
  content: "\F56F"; }

.fa-file-invoice:before {
  content: "\F570"; }

.fa-file-invoice-dollar:before {
  content: "\F571"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-minus:before {
  content: "\F318"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-plus:before {
  content: "\F319"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-prescription:before {
  content: "\F572"; }

.fa-file-signature:before {
  content: "\F573"; }

.fa-file-spreadsheet:before {
  content: "\F65B"; }

.fa-file-times:before {
  content: "\F317"; }

.fa-file-upload:before {
  content: "\F574"; }

.fa-file-user:before {
  content: "\F65C"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-fill:before {
  content: "\F575"; }

.fa-fill-drip:before {
  content: "\F576"; }

.fa-film:before {
  content: "\F008"; }

.fa-film-alt:before {
  content: "\F3A0"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fingerprint:before {
  content: "\F577"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-alt:before {
  content: "\F7E4"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-fire-smoke:before {
  content: "\F74B"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-fireplace:before {
  content: "\F79A"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-first-order-alt:before {
  content: "\F50A"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-fish:before {
  content: "\F578"; }

.fa-fist-raised:before {
  content: "\F6DE"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-alt:before {
  content: "\F74C"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flag-usa:before {
  content: "\F74D"; }

.fa-flame:before {
  content: "\F6DF"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flask-poison:before {
  content: "\F6E0"; }

.fa-flask-potion:before {
  content: "\F6E1"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-flushed:before {
  content: "\F579"; }

.fa-fly:before {
  content: "\F417"; }

.fa-fog:before {
  content: "\F74E"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-minus:before {
  content: "\F65D"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-folder-plus:before {
  content: "\F65E"; }

.fa-folder-times:before {
  content: "\F65F"; }

.fa-folders:before {
  content: "\F660"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-football-helmet:before {
  content: "\F44F"; }

.fa-forklift:before {
  content: "\F47A"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-fragile:before {
  content: "\F4BB"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-frog:before {
  content: "\F52E"; }

.fa-frosty-head:before {
  content: "\F79B"; }

.fa-frown:before {
  content: "\F119"; }

.fa-frown-open:before {
  content: "\F57A"; }

.fa-fulcrum:before {
  content: "\F50B"; }

.fa-function:before {
  content: "\F661"; }

.fa-funnel-dollar:before {
  content: "\F662"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-galactic-republic:before {
  content: "\F50C"; }

.fa-galactic-senate:before {
  content: "\F50D"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gas-pump:before {
  content: "\F52F"; }

.fa-gas-pump-slash:before {
  content: "\F5F4"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-ghost:before {
  content: "\F6E2"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-gift-card:before {
  content: "\F663"; }

.fa-gifts:before {
  content: "\F79C"; }

.fa-gingerbread-man:before {
  content: "\F79D"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass-champagne:before {
  content: "\F79E"; }

.fa-glass-cheers:before {
  content: "\F79F"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glass-martini-alt:before {
  content: "\F57B"; }

.fa-glass-whiskey:before {
  content: "\F7A0"; }

.fa-glass-whiskey-rocks:before {
  content: "\F7A1"; }

.fa-glasses:before {
  content: "\F530"; }

.fa-glasses-alt:before {
  content: "\F5F5"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-globe-africa:before {
  content: "\F57C"; }

.fa-globe-americas:before {
  content: "\F57D"; }

.fa-globe-asia:before {
  content: "\F57E"; }

.fa-globe-europe:before {
  content: "\F7A2"; }

.fa-globe-snow:before {
  content: "\F7A3"; }

.fa-globe-stand:before {
  content: "\F5F6"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-golf-club:before {
  content: "\F451"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-gopuram:before {
  content: "\F664"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-greater-than:before {
  content: "\F531"; }

.fa-greater-than-equal:before {
  content: "\F532"; }

.fa-grimace:before {
  content: "\F57F"; }

.fa-grin:before {
  content: "\F580"; }

.fa-grin-alt:before {
  content: "\F581"; }

.fa-grin-beam:before {
  content: "\F582"; }

.fa-grin-beam-sweat:before {
  content: "\F583"; }

.fa-grin-hearts:before {
  content: "\F584"; }

.fa-grin-squint:before {
  content: "\F585"; }

.fa-grin-squint-tears:before {
  content: "\F586"; }

.fa-grin-stars:before {
  content: "\F587"; }

.fa-grin-tears:before {
  content: "\F588"; }

.fa-grin-tongue:before {
  content: "\F589"; }

.fa-grin-tongue-squint:before {
  content: "\F58A"; }

.fa-grin-tongue-wink:before {
  content: "\F58B"; }

.fa-grin-wink:before {
  content: "\F58C"; }

.fa-grip-horizontal:before {
  content: "\F58D"; }

.fa-grip-lines:before {
  content: "\F7A4"; }

.fa-grip-lines-vertical:before {
  content: "\F7A5"; }

.fa-grip-vertical:before {
  content: "\F58E"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-guitar:before {
  content: "\F7A6"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-h1:before {
  content: "\F313"; }

.fa-h2:before {
  content: "\F314"; }

.fa-h3:before {
  content: "\F315"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hackerrank:before {
  content: "\F5F7"; }

.fa-hammer:before {
  content: "\F6E3"; }

.fa-hammer-war:before {
  content: "\F6E4"; }

.fa-hamsa:before {
  content: "\F665"; }

.fa-hand-heart:before {
  content: "\F4BC"; }

.fa-hand-holding:before {
  content: "\F4BD"; }

.fa-hand-holding-box:before {
  content: "\F47B"; }

.fa-hand-holding-heart:before {
  content: "\F4BE"; }

.fa-hand-holding-magic:before {
  content: "\F6E5"; }

.fa-hand-holding-seedling:before {
  content: "\F4BF"; }

.fa-hand-holding-usd:before {
  content: "\F4C0"; }

.fa-hand-holding-water:before {
  content: "\F4C1"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-receiving:before {
  content: "\F47C"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-hands:before {
  content: "\F4C2"; }

.fa-hands-heart:before {
  content: "\F4C3"; }

.fa-hands-helping:before {
  content: "\F4C4"; }

.fa-hands-usd:before {
  content: "\F4C5"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-handshake-alt:before {
  content: "\F4C6"; }

.fa-hanukiah:before {
  content: "\F6E6"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hat-santa:before {
  content: "\F7A7"; }

.fa-hat-winter:before {
  content: "\F7A8"; }

.fa-hat-witch:before {
  content: "\F6E7"; }

.fa-hat-wizard:before {
  content: "\F6E8"; }

.fa-haykal:before {
  content: "\F666"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-head-side:before {
  content: "\F6E9"; }

.fa-head-vr:before {
  content: "\F6EA"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-headphones-alt:before {
  content: "\F58F"; }

.fa-headset:before {
  content: "\F590"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heart-broken:before {
  content: "\F7A9"; }

.fa-heart-circle:before {
  content: "\F4C7"; }

.fa-heart-rate:before {
  content: "\F5F8"; }

.fa-heart-square:before {
  content: "\F4C8"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-helicopter:before {
  content: "\F533"; }

.fa-helmet-battle:before {
  content: "\F6EB"; }

.fa-hexagon:before {
  content: "\F312"; }

.fa-highlighter:before {
  content: "\F591"; }

.fa-hiking:before {
  content: "\F6EC"; }

.fa-hippo:before {
  content: "\F6ED"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hockey-mask:before {
  content: "\F6EE"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-hockey-sticks:before {
  content: "\F454"; }

.fa-holly-berry:before {
  content: "\F7AA"; }

.fa-home:before {
  content: "\F015"; }

.fa-home-heart:before {
  content: "\F4C9"; }

.fa-hood-cloak:before {
  content: "\F6EF"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-hornbill:before {
  content: "\F592"; }

.fa-horse:before {
  content: "\F6F0"; }

.fa-horse-head:before {
  content: "\F7AB"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hot-tub:before {
  content: "\F593"; }

.fa-hotel:before {
  content: "\F594"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-house-damage:before {
  content: "\F6F1"; }

.fa-house-flood:before {
  content: "\F74F"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-hryvnia:before {
  content: "\F6F2"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-humidity:before {
  content: "\F750"; }

.fa-hurricane:before {
  content: "\F751"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-ice-skate:before {
  content: "\F7AC"; }

.fa-icicles:before {
  content: "\F7AD"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-igloo:before {
  content: "\F7AE"; }

.fa-image:before {
  content: "\F03E"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-inbox-in:before {
  content: "\F310"; }

.fa-inbox-out:before {
  content: "\F311"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-industry-alt:before {
  content: "\F3B3"; }

.fa-infinity:before {
  content: "\F534"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-info-square:before {
  content: "\F30F"; }

.fa-inhaler:before {
  content: "\F5F9"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-integral:before {
  content: "\F667"; }

.fa-intercom:before {
  content: "\F7AF"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-intersection:before {
  content: "\F668"; }

.fa-inventory:before {
  content: "\F480"; }

.fa-invision:before {
  content: "\F7B0"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-jack-o-lantern:before {
  content: "\F30E"; }

.fa-java:before {
  content: "\F4E4"; }

.fa-jedi:before {
  content: "\F669"; }

.fa-jedi-order:before {
  content: "\F50E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-jira:before {
  content: "\F7B1"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joint:before {
  content: "\F595"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-journal-whills:before {
  content: "\F66A"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-kaaba:before {
  content: "\F66B"; }

.fa-kaggle:before {
  content: "\F5FA"; }

.fa-key:before {
  content: "\F084"; }

.fa-key-skeleton:before {
  content: "\F6F3"; }

.fa-keybase:before {
  content: "\F4F5"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-keynote:before {
  content: "\F66C"; }

.fa-khanda:before {
  content: "\F66D"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-kidneys:before {
  content: "\F5FB"; }

.fa-kiss:before {
  content: "\F596"; }

.fa-kiss-beam:before {
  content: "\F597"; }

.fa-kiss-wink-heart:before {
  content: "\F598"; }

.fa-kite:before {
  content: "\F6F4"; }

.fa-kiwi-bird:before {
  content: "\F535"; }

.fa-knife-kitchen:before {
  content: "\F6F5"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-lambda:before {
  content: "\F66E"; }

.fa-lamp:before {
  content: "\F4CA"; }

.fa-landmark:before {
  content: "\F66F"; }

.fa-landmark-alt:before {
  content: "\F752"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laptop-code:before {
  content: "\F5FC"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-laugh:before {
  content: "\F599"; }

.fa-laugh-beam:before {
  content: "\F59A"; }

.fa-laugh-squint:before {
  content: "\F59B"; }

.fa-laugh-wink:before {
  content: "\F59C"; }

.fa-layer-group:before {
  content: "\F5FD"; }

.fa-layer-minus:before {
  content: "\F5FE"; }

.fa-layer-plus:before {
  content: "\F5FF"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leaf-heart:before {
  content: "\F4CB"; }

.fa-leaf-maple:before {
  content: "\F6F6"; }

.fa-leaf-oak:before {
  content: "\F6F7"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-less-than:before {
  content: "\F536"; }

.fa-less-than-equal:before {
  content: "\F537"; }

.fa-level-down:before {
  content: "\F149"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up:before {
  content: "\F148"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-lightbulb-dollar:before {
  content: "\F670"; }

.fa-lightbulb-exclamation:before {
  content: "\F671"; }

.fa-lightbulb-on:before {
  content: "\F672"; }

.fa-lightbulb-slash:before {
  content: "\F673"; }

.fa-lights-holiday:before {
  content: "\F7B2"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lips:before {
  content: "\F600"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location:before {
  content: "\F601"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-location-circle:before {
  content: "\F602"; }

.fa-location-slash:before {
  content: "\F603"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-alt:before {
  content: "\F30D"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-lock-open-alt:before {
  content: "\F3C2"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-long-arrow-down:before {
  content: "\F175"; }

.fa-long-arrow-left:before {
  content: "\F177"; }

.fa-long-arrow-right:before {
  content: "\F178"; }

.fa-long-arrow-up:before {
  content: "\F176"; }

.fa-loveseat:before {
  content: "\F4CC"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luchador:before {
  content: "\F455"; }

.fa-luggage-cart:before {
  content: "\F59D"; }

.fa-lungs:before {
  content: "\F604"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-mace:before {
  content: "\F6F8"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-mail-bulk:before {
  content: "\F674"; }

.fa-mailchimp:before {
  content: "\F59E"; }

.fa-male:before {
  content: "\F183"; }

.fa-mandalorian:before {
  content: "\F50F"; }

.fa-mandolin:before {
  content: "\F6F9"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marked:before {
  content: "\F59F"; }

.fa-map-marked-alt:before {
  content: "\F5A0"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-marker-alt-slash:before {
  content: "\F605"; }

.fa-map-marker-check:before {
  content: "\F606"; }

.fa-map-marker-edit:before {
  content: "\F607"; }

.fa-map-marker-exclamation:before {
  content: "\F608"; }

.fa-map-marker-minus:before {
  content: "\F609"; }

.fa-map-marker-plus:before {
  content: "\F60A"; }

.fa-map-marker-question:before {
  content: "\F60B"; }

.fa-map-marker-slash:before {
  content: "\F60C"; }

.fa-map-marker-smile:before {
  content: "\F60D"; }

.fa-map-marker-times:before {
  content: "\F60E"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-markdown:before {
  content: "\F60F"; }

.fa-marker:before {
  content: "\F5A1"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-mask:before {
  content: "\F6FA"; }

.fa-mastodon:before {
  content: "\F4F6"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-medal:before {
  content: "\F5A2"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-megaphone:before {
  content: "\F675"; }

.fa-megaport:before {
  content: "\F5A3"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-meh-blank:before {
  content: "\F5A4"; }

.fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.fa-memory:before {
  content: "\F538"; }

.fa-mendeley:before {
  content: "\F7B3"; }

.fa-menorah:before {
  content: "\F676"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-meteor:before {
  content: "\F753"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-alt-slash:before {
  content: "\F539"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microscope:before {
  content: "\F610"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-mind-share:before {
  content: "\F677"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-hexagon:before {
  content: "\F307"; }

.fa-minus-octagon:before {
  content: "\F308"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mistletoe:before {
  content: "\F7B4"; }

.fa-mitten:before {
  content: "\F7B5"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-mobile-android:before {
  content: "\F3CE"; }

.fa-mobile-android-alt:before {
  content: "\F3CF"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-money-bill-wave:before {
  content: "\F53A"; }

.fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.fa-money-check:before {
  content: "\F53C"; }

.fa-money-check-alt:before {
  content: "\F53D"; }

.fa-monitor-heart-rate:before {
  content: "\F611"; }

.fa-monkey:before {
  content: "\F6FB"; }

.fa-monument:before {
  content: "\F5A6"; }

.fa-moon:before {
  content: "\F186"; }

.fa-moon-cloud:before {
  content: "\F754"; }

.fa-moon-stars:before {
  content: "\F755"; }

.fa-mortar-pestle:before {
  content: "\F5A7"; }

.fa-mosque:before {
  content: "\F678"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mountain:before {
  content: "\F6FC"; }

.fa-mountains:before {
  content: "\F6FD"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-mug-hot:before {
  content: "\F7B6"; }

.fa-mug-marshmallows:before {
  content: "\F7B7"; }

.fa-music:before {
  content: "\F001"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-narwhal:before {
  content: "\F6FE"; }

.fa-neos:before {
  content: "\F612"; }

.fa-network-wired:before {
  content: "\F6FF"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nimblr:before {
  content: "\F5A8"; }

.fa-nintendo-switch:before {
  content: "\F418"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-not-equal:before {
  content: "\F53E"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-octagon:before {
  content: "\F306"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-oil-can:before {
  content: "\F613"; }

.fa-oil-temp:before {
  content: "\F614"; }

.fa-old-republic:before {
  content: "\F510"; }

.fa-om:before {
  content: "\F679"; }

.fa-omega:before {
  content: "\F67A"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-ornament:before {
  content: "\F7B8"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-otter:before {
  content: "\F700"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-paint-brush-alt:before {
  content: "\F5A9"; }

.fa-paint-roller:before {
  content: "\F5AA"; }

.fa-palette:before {
  content: "\F53F"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-pallet-alt:before {
  content: "\F483"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-parachute-box:before {
  content: "\F4CD"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-parking:before {
  content: "\F540"; }

.fa-parking-circle:before {
  content: "\F615"; }

.fa-parking-circle-slash:before {
  content: "\F616"; }

.fa-parking-slash:before {
  content: "\F617"; }

.fa-passport:before {
  content: "\F5AB"; }

.fa-pastafarianism:before {
  content: "\F67B"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paw-alt:before {
  content: "\F701"; }

.fa-paw-claws:before {
  content: "\F702"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-peace:before {
  content: "\F67C"; }

.fa-pegasus:before {
  content: "\F703"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-fancy:before {
  content: "\F5AC"; }

.fa-pen-nib:before {
  content: "\F5AD"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil:before {
  content: "\F040"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pencil-paintbrush:before {
  content: "\F618"; }

.fa-pencil-ruler:before {
  content: "\F5AE"; }

.fa-pennant:before {
  content: "\F456"; }

.fa-penny-arcade:before {
  content: "\F704"; }

.fa-people-carry:before {
  content: "\F4CE"; }

.fa-percent:before {
  content: "\F295"; }

.fa-percentage:before {
  content: "\F541"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-person-booth:before {
  content: "\F756"; }

.fa-person-carry:before {
  content: "\F4CF"; }

.fa-person-dolly:before {
  content: "\F4D0"; }

.fa-person-dolly-empty:before {
  content: "\F4D1"; }

.fa-person-sign:before {
  content: "\F757"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phoenix-squadron:before {
  content: "\F511"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-office:before {
  content: "\F67D"; }

.fa-phone-plus:before {
  content: "\F4D2"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-php:before {
  content: "\F457"; }

.fa-pi:before {
  content: "\F67E"; }

.fa-pie:before {
  content: "\F705"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-hat:before {
  content: "\F4E5"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-pig:before {
  content: "\F706"; }

.fa-piggy-bank:before {
  content: "\F4D3"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-place-of-worship:before {
  content: "\F67F"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-alt:before {
  content: "\F3DE"; }

.fa-plane-arrival:before {
  content: "\F5AF"; }

.fa-plane-departure:before {
  content: "\F5B0"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-hexagon:before {
  content: "\F300"; }

.fa-plus-octagon:before {
  content: "\F301"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-podium:before {
  content: "\F680"; }

.fa-podium-star:before {
  content: "\F758"; }

.fa-poll:before {
  content: "\F681"; }

.fa-poll-h:before {
  content: "\F682"; }

.fa-poll-people:before {
  content: "\F759"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-poo-storm:before {
  content: "\F75A"; }

.fa-poop:before {
  content: "\F619"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-pray:before {
  content: "\F683"; }

.fa-praying-hands:before {
  content: "\F684"; }

.fa-prescription:before {
  content: "\F5B1"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-presentation:before {
  content: "\F685"; }

.fa-print:before {
  content: "\F02F"; }

.fa-print-slash:before {
  content: "\F686"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-project-diagram:before {
  content: "\F542"; }

.fa-pumpkin:before {
  content: "\F707"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-question-square:before {
  content: "\F2FD"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-quran:before {
  content: "\F687"; }

.fa-r-project:before {
  content: "\F4F7"; }

.fa-rabbit:before {
  content: "\F708"; }

.fa-rabbit-fast:before {
  content: "\F709"; }

.fa-racquet:before {
  content: "\F45A"; }

.fa-radiation:before {
  content: "\F7B9"; }

.fa-radiation-alt:before {
  content: "\F7BA"; }

.fa-rainbow:before {
  content: "\F75B"; }

.fa-raindrops:before {
  content: "\F75C"; }

.fa-ram:before {
  content: "\F70A"; }

.fa-ramp-loading:before {
  content: "\F4D4"; }

.fa-random:before {
  content: "\F074"; }

.fa-raspberry-pi:before {
  content: "\F7BB"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-react:before {
  content: "\F41B"; }

.fa-reacteurope:before {
  content: "\F75D"; }

.fa-readme:before {
  content: "\F4D5"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-receipt:before {
  content: "\F543"; }

.fa-rectangle-landscape:before {
  content: "\F2FA"; }

.fa-rectangle-portrait:before {
  content: "\F2FB"; }

.fa-rectangle-wide:before {
  content: "\F2FC"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redhat:before {
  content: "\F7BC"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-repeat:before {
  content: "\F363"; }

.fa-repeat-1:before {
  content: "\F365"; }

.fa-repeat-1-alt:before {
  content: "\F366"; }

.fa-repeat-alt:before {
  content: "\F364"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-republican:before {
  content: "\F75E"; }

.fa-researchgate:before {
  content: "\F4F8"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-restroom:before {
  content: "\F7BD"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-retweet-alt:before {
  content: "\F361"; }

.fa-rev:before {
  content: "\F5B2"; }

.fa-ribbon:before {
  content: "\F4D6"; }

.fa-ring:before {
  content: "\F70B"; }

.fa-road:before {
  content: "\F018"; }

.fa-robot:before {
  content: "\F544"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-route:before {
  content: "\F4D7"; }

.fa-route-highway:before {
  content: "\F61A"; }

.fa-route-interstate:before {
  content: "\F61B"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-ruler:before {
  content: "\F545"; }

.fa-ruler-combined:before {
  content: "\F546"; }

.fa-ruler-horizontal:before {
  content: "\F547"; }

.fa-ruler-triangle:before {
  content: "\F61C"; }

.fa-ruler-vertical:before {
  content: "\F548"; }

.fa-running:before {
  content: "\F70C"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-rv:before {
  content: "\F7BE"; }

.fa-sad-cry:before {
  content: "\F5B3"; }

.fa-sad-tear:before {
  content: "\F5B4"; }

.fa-safari:before {
  content: "\F267"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-satellite:before {
  content: "\F7BF"; }

.fa-satellite-dish:before {
  content: "\F7C0"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-scalpel:before {
  content: "\F61D"; }

.fa-scalpel-path:before {
  content: "\F61E"; }

.fa-scanner:before {
  content: "\F488"; }

.fa-scanner-keyboard:before {
  content: "\F489"; }

.fa-scanner-touchscreen:before {
  content: "\F48A"; }

.fa-scarecrow:before {
  content: "\F70D"; }

.fa-scarf:before {
  content: "\F7C1"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-school:before {
  content: "\F549"; }

.fa-screwdriver:before {
  content: "\F54A"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scroll:before {
  content: "\F70E"; }

.fa-scroll-old:before {
  content: "\F70F"; }

.fa-scrubber:before {
  content: "\F2F8"; }

.fa-scythe:before {
  content: "\F710"; }

.fa-sd-card:before {
  content: "\F7C2"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-dollar:before {
  content: "\F688"; }

.fa-search-location:before {
  content: "\F689"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-seedling:before {
  content: "\F4D8"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-shapes:before {
  content: "\F61F"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-all:before {
  content: "\F367"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-sheep:before {
  content: "\F711"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield:before {
  content: "\F132"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-shield-check:before {
  content: "\F2F7"; }

.fa-shield-cross:before {
  content: "\F712"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shipping-timed:before {
  content: "\F48C"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shoe-prints:before {
  content: "\F54B"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shopware:before {
  content: "\F5B5"; }

.fa-shovel:before {
  content: "\F713"; }

.fa-shovel-snow:before {
  content: "\F7C3"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shredder:before {
  content: "\F68A"; }

.fa-shuttle-van:before {
  content: "\F5B6"; }

.fa-shuttlecock:before {
  content: "\F45B"; }

.fa-sigma:before {
  content: "\F68B"; }

.fa-sign:before {
  content: "\F4D9"; }

.fa-sign-in:before {
  content: "\F090"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out:before {
  content: "\F08B"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-signal-1:before {
  content: "\F68C"; }

.fa-signal-2:before {
  content: "\F68D"; }

.fa-signal-3:before {
  content: "\F68E"; }

.fa-signal-4:before {
  content: "\F68F"; }

.fa-signal-alt:before {
  content: "\F690"; }

.fa-signal-alt-1:before {
  content: "\F691"; }

.fa-signal-alt-2:before {
  content: "\F692"; }

.fa-signal-alt-3:before {
  content: "\F693"; }

.fa-signal-alt-slash:before {
  content: "\F694"; }

.fa-signal-slash:before {
  content: "\F695"; }

.fa-signature:before {
  content: "\F5B7"; }

.fa-sim-card:before {
  content: "\F7C4"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-sith:before {
  content: "\F512"; }

.fa-skating:before {
  content: "\F7C5"; }

.fa-skeleton:before {
  content: "\F620"; }

.fa-sketch:before {
  content: "\F7C6"; }

.fa-ski-jump:before {
  content: "\F7C7"; }

.fa-ski-lift:before {
  content: "\F7C8"; }

.fa-skiing:before {
  content: "\F7C9"; }

.fa-skiing-nordic:before {
  content: "\F7CA"; }

.fa-skull:before {
  content: "\F54C"; }

.fa-skull-crossbones:before {
  content: "\F714"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-slash:before {
  content: "\F715"; }

.fa-sledding:before {
  content: "\F7CB"; }

.fa-sleigh:before {
  content: "\F7CC"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-sliders-h-square:before {
  content: "\F3F0"; }

.fa-sliders-v:before {
  content: "\F3F1"; }

.fa-sliders-v-square:before {
  content: "\F3F2"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smile-beam:before {
  content: "\F5B8"; }

.fa-smile-plus:before {
  content: "\F5B9"; }

.fa-smile-wink:before {
  content: "\F4DA"; }

.fa-smog:before {
  content: "\F75F"; }

.fa-smoke:before {
  content: "\F760"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-smoking-ban:before {
  content: "\F54D"; }

.fa-sms:before {
  content: "\F7CD"; }

.fa-snake:before {
  content: "\F716"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snow-blowing:before {
  content: "\F761"; }

.fa-snowboarding:before {
  content: "\F7CE"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-snowflakes:before {
  content: "\F7CF"; }

.fa-snowman:before {
  content: "\F7D0"; }

.fa-snowmobile:before {
  content: "\F7D1"; }

.fa-snowplow:before {
  content: "\F7D2"; }

.fa-socks:before {
  content: "\F696"; }

.fa-solar-panel:before {
  content: "\F5BA"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-sourcetree:before {
  content: "\F7D3"; }

.fa-spa:before {
  content: "\F5BB"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-spade:before {
  content: "\F2F4"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-spider:before {
  content: "\F717"; }

.fa-spider-black-widow:before {
  content: "\F718"; }

.fa-spider-web:before {
  content: "\F719"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-spinner-third:before {
  content: "\F3F4"; }

.fa-splotch:before {
  content: "\F5BC"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-spray-can:before {
  content: "\F5BD"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-square-root:before {
  content: "\F697"; }

.fa-square-root-alt:before {
  content: "\F698"; }

.fa-squarespace:before {
  content: "\F5BE"; }

.fa-squirrel:before {
  content: "\F71A"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-staff:before {
  content: "\F71B"; }

.fa-stamp:before {
  content: "\F5BF"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-and-crescent:before {
  content: "\F699"; }

.fa-star-christmas:before {
  content: "\F7D4"; }

.fa-star-exclamation:before {
  content: "\F2F3"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-star-half-alt:before {
  content: "\F5C0"; }

.fa-star-of-david:before {
  content: "\F69A"; }

.fa-star-of-life:before {
  content: "\F621"; }

.fa-stars:before {
  content: "\F762"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-steering-wheel:before {
  content: "\F622"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stocking:before {
  content: "\F7D5"; }

.fa-stomach:before {
  content: "\F623"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-store:before {
  content: "\F54E"; }

.fa-store-alt:before {
  content: "\F54F"; }

.fa-strava:before {
  content: "\F428"; }

.fa-stream:before {
  content: "\F550"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-stroopwafel:before {
  content: "\F551"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-suitcase-rolling:before {
  content: "\F5C1"; }

.fa-sun:before {
  content: "\F185"; }

.fa-sun-cloud:before {
  content: "\F763"; }

.fa-sun-dust:before {
  content: "\F764"; }

.fa-sun-haze:before {
  content: "\F765"; }

.fa-sunrise:before {
  content: "\F766"; }

.fa-sunset:before {
  content: "\F767"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-surprise:before {
  content: "\F5C2"; }

.fa-suse:before {
  content: "\F7D6"; }

.fa-swatchbook:before {
  content: "\F5C3"; }

.fa-swimmer:before {
  content: "\F5C4"; }

.fa-swimming-pool:before {
  content: "\F5C5"; }

.fa-sword:before {
  content: "\F71C"; }

.fa-swords:before {
  content: "\F71D"; }

.fa-synagogue:before {
  content: "\F69B"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablet-android:before {
  content: "\F3FB"; }

.fa-tablet-android-alt:before {
  content: "\F3FC"; }

.fa-tablet-rugged:before {
  content: "\F48F"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer:before {
  content: "\F0E4"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tachometer-alt-average:before {
  content: "\F624"; }

.fa-tachometer-alt-fast:before {
  content: "\F625"; }

.fa-tachometer-alt-fastest:before {
  content: "\F626"; }

.fa-tachometer-alt-slow:before {
  content: "\F627"; }

.fa-tachometer-alt-slowest:before {
  content: "\F628"; }

.fa-tachometer-average:before {
  content: "\F629"; }

.fa-tachometer-fast:before {
  content: "\F62A"; }

.fa-tachometer-fastest:before {
  content: "\F62B"; }

.fa-tachometer-slow:before {
  content: "\F62C"; }

.fa-tachometer-slowest:before {
  content: "\F62D"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tally:before {
  content: "\F69C"; }

.fa-tape:before {
  content: "\F4DB"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-teamspeak:before {
  content: "\F4F9"; }

.fa-teeth:before {
  content: "\F62E"; }

.fa-teeth-open:before {
  content: "\F62F"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-temperature-frigid:before {
  content: "\F768"; }

.fa-temperature-high:before {
  content: "\F769"; }

.fa-temperature-hot:before {
  content: "\F76A"; }

.fa-temperature-low:before {
  content: "\F76B"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tenge:before {
  content: "\F7D7"; }

.fa-tennis-ball:before {
  content: "\F45E"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-the-red-yeti:before {
  content: "\F69D"; }

.fa-theater-masks:before {
  content: "\F630"; }

.fa-themeco:before {
  content: "\F5C6"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-theta:before {
  content: "\F69E"; }

.fa-think-peaks:before {
  content: "\F731"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-thunderstorm:before {
  content: "\F76C"; }

.fa-thunderstorm-moon:before {
  content: "\F76D"; }

.fa-thunderstorm-sun:before {
  content: "\F76E"; }

.fa-ticket:before {
  content: "\F145"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-tilde:before {
  content: "\F69F"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-times-hexagon:before {
  content: "\F2EE"; }

.fa-times-octagon:before {
  content: "\F2F0"; }

.fa-times-square:before {
  content: "\F2D3"; }

.fa-tint:before {
  content: "\F043"; }

.fa-tint-slash:before {
  content: "\F5C7"; }

.fa-tire:before {
  content: "\F631"; }

.fa-tire-flat:before {
  content: "\F632"; }

.fa-tire-pressure-warning:before {
  content: "\F633"; }

.fa-tire-rugged:before {
  content: "\F634"; }

.fa-tired:before {
  content: "\F5C8"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-toilet:before {
  content: "\F7D8"; }

.fa-toilet-paper:before {
  content: "\F71E"; }

.fa-toilet-paper-alt:before {
  content: "\F71F"; }

.fa-tombstone:before {
  content: "\F720"; }

.fa-tombstone-alt:before {
  content: "\F721"; }

.fa-toolbox:before {
  content: "\F552"; }

.fa-tools:before {
  content: "\F7D9"; }

.fa-tooth:before {
  content: "\F5C9"; }

.fa-toothbrush:before {
  content: "\F635"; }

.fa-torah:before {
  content: "\F6A0"; }

.fa-torii-gate:before {
  content: "\F6A1"; }

.fa-tornado:before {
  content: "\F76F"; }

.fa-tractor:before {
  content: "\F722"; }

.fa-trade-federation:before {
  content: "\F513"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-traffic-cone:before {
  content: "\F636"; }

.fa-traffic-light:before {
  content: "\F637"; }

.fa-traffic-light-go:before {
  content: "\F638"; }

.fa-traffic-light-slow:before {
  content: "\F639"; }

.fa-traffic-light-stop:before {
  content: "\F63A"; }

.fa-train:before {
  content: "\F238"; }

.fa-tram:before {
  content: "\F7DA"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-treasure-chest:before {
  content: "\F723"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-tree-alt:before {
  content: "\F400"; }

.fa-tree-christmas:before {
  content: "\F7DB"; }

.fa-tree-decorated:before {
  content: "\F7DC"; }

.fa-tree-large:before {
  content: "\F7DD"; }

.fa-trees:before {
  content: "\F724"; }

.fa-trello:before {
  content: "\F181"; }

.fa-triangle:before {
  content: "\F2EC"; }

.fa-tripadvisor:before {
  content: "\F262"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-trophy-alt:before {
  content: "\F2EB"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-truck-container:before {
  content: "\F4DC"; }

.fa-truck-couch:before {
  content: "\F4DD"; }

.fa-truck-loading:before {
  content: "\F4DE"; }

.fa-truck-monster:before {
  content: "\F63B"; }

.fa-truck-moving:before {
  content: "\F4DF"; }

.fa-truck-pickup:before {
  content: "\F63C"; }

.fa-truck-plow:before {
  content: "\F7DE"; }

.fa-truck-ramp:before {
  content: "\F4E0"; }

.fa-tshirt:before {
  content: "\F553"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-turkey:before {
  content: "\F725"; }

.fa-turtle:before {
  content: "\F726"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-tv-retro:before {
  content: "\F401"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-ubuntu:before {
  content: "\F7DF"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-umbrella-beach:before {
  content: "\F5CA"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-unicorn:before {
  content: "\F727"; }

.fa-union:before {
  content: "\F6A2"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-ups:before {
  content: "\F7E0"; }

.fa-usb:before {
  content: "\F287"; }

.fa-usd-circle:before {
  content: "\F2E8"; }

.fa-usd-square:before {
  content: "\F2E9"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-alt-slash:before {
  content: "\F4FA"; }

.fa-user-astronaut:before {
  content: "\F4FB"; }

.fa-user-chart:before {
  content: "\F6A3"; }

.fa-user-check:before {
  content: "\F4FC"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-clock:before {
  content: "\F4FD"; }

.fa-user-cog:before {
  content: "\F4FE"; }

.fa-user-crown:before {
  content: "\F6A4"; }

.fa-user-edit:before {
  content: "\F4FF"; }

.fa-user-friends:before {
  content: "\F500"; }

.fa-user-graduate:before {
  content: "\F501"; }

.fa-user-injured:before {
  content: "\F728"; }

.fa-user-lock:before {
  content: "\F502"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-minus:before {
  content: "\F503"; }

.fa-user-ninja:before {
  content: "\F504"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-shield:before {
  content: "\F505"; }

.fa-user-slash:before {
  content: "\F506"; }

.fa-user-tag:before {
  content: "\F507"; }

.fa-user-tie:before {
  content: "\F508"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-users-class:before {
  content: "\F63D"; }

.fa-users-cog:before {
  content: "\F509"; }

.fa-users-crown:before {
  content: "\F6A5"; }

.fa-usps:before {
  content: "\F7E1"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-fork:before {
  content: "\F2E3"; }

.fa-utensil-knife:before {
  content: "\F2E4"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-utensils-alt:before {
  content: "\F2E6"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-value-absolute:before {
  content: "\F6A6"; }

.fa-vector-square:before {
  content: "\F5CB"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-video-plus:before {
  content: "\F4E1"; }

.fa-video-slash:before {
  content: "\F4E2"; }

.fa-vihara:before {
  content: "\F6A7"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-volcano:before {
  content: "\F770"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume:before {
  content: "\F6A8"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F6A9"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-slash:before {
  content: "\F2E2"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vote-nay:before {
  content: "\F771"; }

.fa-vote-yea:before {
  content: "\F772"; }

.fa-vr-cardboard:before {
  content: "\F729"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-walking:before {
  content: "\F554"; }

.fa-wallet:before {
  content: "\F555"; }

.fa-wand:before {
  content: "\F72A"; }

.fa-wand-magic:before {
  content: "\F72B"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-warehouse-alt:before {
  content: "\F495"; }

.fa-watch:before {
  content: "\F2E1"; }

.fa-watch-fitness:before {
  content: "\F63E"; }

.fa-water:before {
  content: "\F773"; }

.fa-water-lower:before {
  content: "\F774"; }

.fa-water-rise:before {
  content: "\F775"; }

.fa-weebly:before {
  content: "\F5CC"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weight-hanging:before {
  content: "\F5CD"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whale:before {
  content: "\F72C"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheat:before {
  content: "\F72D"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whistle:before {
  content: "\F460"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wifi-1:before {
  content: "\F6AA"; }

.fa-wifi-2:before {
  content: "\F6AB"; }

.fa-wifi-slash:before {
  content: "\F6AC"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-wind:before {
  content: "\F72E"; }

.fa-wind-warning:before {
  content: "\F776"; }

.fa-window:before {
  content: "\F40E"; }

.fa-window-alt:before {
  content: "\F40F"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-windsock:before {
  content: "\F777"; }

.fa-wine-bottle:before {
  content: "\F72F"; }

.fa-wine-glass:before {
  content: "\F4E3"; }

.fa-wine-glass-alt:before {
  content: "\F5CE"; }

.fa-wix:before {
  content: "\F5CF"; }

.fa-wizards-of-the-coast:before {
  content: "\F730"; }

.fa-wolf-pack-battalion:before {
  content: "\F514"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wpressr:before {
  content: "\F3E4"; }

.fa-wreath:before {
  content: "\F7E2"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yarn:before {
  content: "\F7E3"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yin-yang:before {
  content: "\F6AD"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.fa-zhihu:before {
  content: "\F63F"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/fa-brands-400.586bdc7d.eot);
  src: url(/static/media/fa-brands-400.586bdc7d.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-brands-400.68a68036.woff2) format("woff2"), url(/static/media/fa-brands-400.8270e503.woff) format("woff"), url(/static/media/fa-brands-400.6a004ddb.ttf) format("truetype"), url(/static/media/fa-brands-400.277706fe.svg#fontawesome) format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/fa-light-300.fd4e4570.eot);
  src: url(/static/media/fa-light-300.fd4e4570.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-light-300.23fbd7c6.woff2) format("woff2"), url(/static/media/fa-light-300.5024e9b2.woff) format("woff"), url(/static/media/fa-light-300.80329a35.ttf) format("truetype"), url(/static/media/fa-light-300.7fc88777.svg#fontawesome) format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/fa-regular-400.b2d22036.eot);
  src: url(/static/media/fa-regular-400.b2d22036.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.2fa6bcb8.woff2) format("woff2"), url(/static/media/fa-regular-400.559f4a5f.woff) format("woff"), url(/static/media/fa-regular-400.bdd14bcd.ttf) format("truetype"), url(/static/media/fa-regular-400.dc1995da.svg#fontawesome) format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/fa-solid-900.4279e797.eot);
  src: url(/static/media/fa-solid-900.4279e797.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.893b3ef2.woff2) format("woff2"), url(/static/media/fa-solid-900.a2a89323.woff) format("woff"), url(/static/media/fa-solid-900.3a6ff681.ttf) format("truetype"), url(/static/media/fa-solid-900.5e46e927.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }


h3 {
    background: blue;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}
.center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}
.center h3 {
    opacity: 0.8;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.content {
    padding: 20px;
    margin: auto;
    width: 90%;
}

.slick-slide .image {
    padding: 10px;
}
.slick-slide img {
    display: block;
}

.slick-dots {
    margin-left: 0;
}
.slick-thumb {
    bottom: -45px;
}
.slick-thumb li {
    width: 60px;
    height: 45px;
}

.slick-track {
    margin-left: 0 !important;
}

.slick-arrow {
    position: absolute;
    right: 1px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: 0 2px 4px 0 rgba(81, 81, 81, .2);
    background-color: #fff;
    border-radius: 50%;
    padding: 0;
    border: 0;
    z-index: 1;
}

.slick-arrow:hover {
    opacity: 1 !important;
}

.slick-arrow:before {
    content: '';
    width: 7px;
    height: 12px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: url(/static/media/album-arrow-right.c94b27b6.svg) center/cover no-repeat;
}

.slick-prev {
    left: 1px;
}

.slick-next:hover {
    background-color: #fff;
}

.slick-prev:hover {
    background-color: #fff;
}

.slick-prev:before {
    -webkit-transform: scale(-1, 1) translate(50%, -50%) !important;
            transform: scale(-1, 1) translate(50%, -50%) !important;
}

@media (max-width: 768px) {
    h3 {
        font-size: 1.5rem;
    }
    .center {
        margin-left: -40px;
        margin-right: -40px;
    }
    .center .slick-center h3 {
        color: #e67e22;
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    .center h3 {
        opacity: 0.8;
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
    }
}
.slick-vertical .slick-slide {
    height: 180px;
}

.slick-arrow:hover {
   opacity: 0.7;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    outline: none;
    background: #fff;
}
