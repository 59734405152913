body {
  font-family: 'Proxima Nova', sans-serif;
  margin: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25;
  color: #4b4c59;
  background-color: #fcfcfd;
  overflow: hidden;
}

body .modal-open {
  overflow: hidden;
}

input {
  caret-color: #4a90e2;
}

html {
  font-size: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
}

@media (min-width: 1950px) {
  html {
    font-size: 18px
  }
}

@media (min-width: 2300px) {
  html {
    font-size: 20px
  }
}

@media (min-width: 2600px) {
  html {
    font-size: 22px
  }
}

@media (min-width: 2900px) {
  html {
    font-size: 24px
  }
}

@media (max-height: 580px) and (min-width: 768px) {
  html {
    font-size: 14px
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 14px
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 12px
  }
}

@media (max-width: 870px) {
  html {
    font-size: 10px
  }
}

*, ::after, ::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

html.popup-opened {
  overflow: hidden
}

@media (max-width: 767px) {
  html {
    font-size: 16px;
    background-color: #fff
  }

  body {
    background-color: #fff;
  }
}

@media (max-width: 480px) {
  body {
    font-size: .75rem
  }
}

code {
  font-family: monospace, monospace;
  font-size: 1em
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%
}

main {
  display: block
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace, monospace;
  font-size: 1em
}

a {
  background-color: transparent;
  color: #4b4c59;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline dotted
}

b, strong {
  font-weight: bolder
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -0.25em
}

sup {
  top: -0.5em
}

img {
  border-style: none
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0
}

button, input {
  overflow: visible
}

button, select {
  text-transform: none
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  padding: .35em .75em .625em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

@media (max-width: 767px) {
  input {
    font-size: 16px !important;
  }

  textarea {
    font-size: 16px !important;
  }
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

details {
  display: block
}

summary {
  display: list-item
}

template {
  display: none
}

[hidden] {
  display: none;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  width: 82px;
  height: 82px;
}

.dropzone .dz-preview .dz-image {
  width: 82px !important;
  height: 82px !important;
}

div.filepicker {
  background-color: #ffffff !important;
}

.dropzone .dz-preview .dz-image {
  border-radius: 0 !important;
}

.filepicker.dropzone.dz-clickable {
  display: flex;
  position: relative;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  border: 0 !important;
  min-height: 241.5px;
  margin-bottom: 20px;
  background: url(../src/assets/img/photo-upload-bg.svg) center/cover no-repeat
}

@media (max-width: 767px) {
  .filepicker.dropzone.dz-clickable {
    background: transparent !important;
  }
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
  font-weight: 600;
  font-size: inherit;
}

.dz-message {
  font-family: Proxima Nova, sans-serif;
  margin: 25px 0 0 50% !important;
  transform: translateX(-50%);
}

.dropzone .dz-preview .dz-details {
  display: none;
}

.dropzone .dz-preview {
  margin: 3px !important;
  min-height: 80px !important;
}

.dropzone .dz-preview .dz-error-message {
  display: none !important;
}

.Crop--circle .ReactCrop__crop-selection {
  border-radius: 50%;
}

*, ::after, ::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit
}

@media (max-width: 767px) {
  .ReactCrop {
    width: 100%;
  }
}

.react-switch-handle {
  position: absolute !important;
  top: 10% !important;
  left: 3px !important;
  width: 0.875rem !important;
  height: 0.875rem !important;
  border-radius: 50% !important;
}

.react-switch-bg {
  width: 2.125rem !important;
  height: 1.125rem !important;
  margin: 0 !important;
  border-radius: 9px !important;
  background-color: #edeff4 !important;
}

.css-16pqwjk-indicatorContainer {
  padding: 4px !important;
}

.css-1thkkgx-indicatorContainer {
  padding: 4px !important;
  color: hsl(0, 0%, 80%) !important;
}

.css-bg1rzq-control {
  border-radius: 0 !important;
  height: 1.875rem !important;
  min-height: 1.875rem !important;
}

.css-1szy77t-control {
  border-radius: 0 !important;
  height: 1.875rem !important;
  min-height: 1.875rem !important;
  border: 1px solid #dadee8 !important;
  box-shadow: none !important;
}

.css-1szy77t-control:hover {
  border: 1px solid #dadee8 !important;
  box-shadow: none !important;
}

.css-16pqwjk-indicatorContainer:hover {
  color: hsl(0, 0%, 80%) !important;
}

.react-slick__wrapper {
  width: 100%;
  position: relative;
  max-height: 6.5925rem;
}

.react-slick__wrapper .slick-list {
  overflow: visible;
}

.react-slick__wrapper .slick-slide {
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .5s;
}

.react-slick__wrapper .slick-slide.slick-active {
  opacity: 1;
}

@media (max-width: 767px) {
  .react-slick__wrapper {
    overflow: visible;
    max-height: 8rem;
    margin: 0;
    border: none;
  }
}

@media (max-width: 767px) {
  #_hj-f5b2a1eb-9b07_feedback[data-state="minimized"] {
    display: none;
  }

  iframe#launcher {
    bottom: 55px !important;
    right: -15px !important;
  }
}

#_hj-f5b2a1eb-9b07_feedback[data-viewmode="desktop"][data-minimized-position="middle_right"] #_hj-f5b2a1eb-9b07_feedback_minimized {
  bottom: 300px !important;
}

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label {
  background-color: #3b5a9a !important;
}

#_hj-f5b2a1eb-9b07_feedback ._hj-f5b2a1eb-9b07_feedback_minimized_label ._hj-f5b2a1eb-9b07_icon_emotion_default .path2:before {
  color: #3b5a9a !important;
}

.popperProfile-wrapper {
  position: relative;
  padding: 15px 11px 13px 15px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  z-index: 1;
}

.popperProfile-wrapper:before {
  position: absolute;
  right: 12px;
  top: -6px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
}

.popperProfile-icon {
  margin-right: 10px;
}

.popperProfile-description {
  font-size: 12px;
  line-height: 14px;
  color: #4B4C59;
}

.settings-wrapper {
  width: 100%;
  max-width: 40.3125rem;
}

@media (max-width: 768px) {
  .settings-wrapper {
    max-width: 100%;
  }
}

.settings-confirm-wrapper {
  display: flex;
  background-color: #EDEFF4;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 2px;
  padding: 24px 20px;
  margin-bottom: 20px;
}

.settings-confirm-icon {
  margin-right: 14px;
}

.settings-confirm-description p {
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: #4B4C59;
}

.blue-button {
  background-color: #3B5A9A;
  box-shadow: 0px 2px 4px rgba(221, 44, 87, 0.2);
  border-radius: 2px;
  min-width: 100px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

.credit-status {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid #DADEE8;
  box-sizing: border-box;
}

.credit-status.is-active {
  position: relative;
  border-color: #7ED321;
  background-color: #7ED321;
}

.credit-status.is-active:before {
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  left: 5px;
  top: 1.5px;
  position: absolute;
  content: '';
}

.blue-button--profile {
  margin-left: 0;
}

.check-validate-icon {
  display: flex;
}

.check-validate-popper {
  position: absolute;
  z-index: 2;
  display: none;
  left: -5px;
  bottom: 22px;
  padding: 4px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #4B4C59;
  width: 150px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.5);
  border-radius: 2px;
  box-sizing: border-box;
  white-space: normal;
}

.check-validate-popper:before {
  position: absolute;
  bottom: -4px;
  left: 6px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
}

.check-validate {
  position: relative;
  margin-right: 6px;
}

.check-validate:hover .check-validate-popper {
  display: block;
}

.photo-wrapper {
  position: relative;
}

.blue-button--start {
  position: absolute;
  display: none;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -85%);
  z-index: 1;
  background-color: #4A90E2;
  margin-left: 0;
}

.photo-wrapper:hover .blue-button--start {
  display: block;
}

.modal-wrapper {
  width: 100%;
  max-width: 738px;
  margin: auto;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(184, 191, 211, 0.5);
  border-radius: 2px;
  padding: 90px 90px 100px;
}

.modal-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.207059px;
  color: rgba(51, 50, 56, 0.836589);
  margin-bottom: 32px;
}

.modal-description,
.modal-list-title {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.11px;
  color: rgba(35, 35, 39, 0.836589);
}

.modal-list-title {
  font-weight: bold;
}

.modal-list-item {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.11px;
  color: rgba(35, 35, 39, 0.836589);
  position: relative;
}

.modal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.modal-list-wrapper {
  margin-top: 40px;
}

.modal-list-item-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #DE60AD;
}

.modal-button {
  margin: 0 20px;
  padding: 18px 25px;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.11px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  border: none;
}

.modal-button--cancel {
  border: 1px solid #D3D3D3;
  background-color: #ffffff;
  color: rgba(139, 139, 139, 0.836589);
}

.modal-button--thank {
  background-color: #4A90E2;
  color: #ffffff;
}

.modal-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .modal-title {
    font-size: 24px;
    line-height: 26px;
  }

  .modal-wrapper {
    padding: 20px 20px 25px;
  }

  .catalog {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
}

.verify-email-wrapper {
  position: relative;
  overflow: hidden;
  margin: .78125rem;
  width: 11.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .verify-email-wrapper {
    width: calc(100% / 3 - .75rem);
    margin: .375rem;
  }
}

@media (max-width: 590px) {
  .verify-email-wrapper {
    width: calc(100% / 2 - .375rem);
    min-width: 8.75rem;
    margin: .1875rem;
  }
}
.verify-email {
  background-color: #EDEFF4;
  border-radius: 0.125rem;
  padding: 1.25rem 0.25rem 0.875rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verify-email__title {
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: #4B4C59;
  font-weight: 600;
}

.verify-email__description {
  font-size: 0.813rem;
  line-height: 1.25rem;
  color: #4B4C59
}

.verify-email__icon {
  margin: 0.75rem 0;
}

.verify-email__icon svg {
  width: 4.313rem;
  height: 3.25rem;
}

.verify-email__email {
  color: #4A90E2;
  display: block;
}

.verify-email-link {
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: #4B4C59;
  text-decoration: none;
  font-weight: 600;
  margin: 0 0.875rem;
  cursor: pointer;
}

.verify-email-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.verify-email-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  flex: 0 0 auto;
}

.verify-message {
  background-color: #EDEFF4;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 0.125rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
  position: relative;
}

@media (max-width: 767px) {
  .verify-message {
    padding: .625rem 1.5rem .625rem .625rem;
  }
}

.verify-message__close-btn {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  width: 0.813rem;
  height: 0.813rem;
  cursor: pointer;
}

.verify-message__close-btn:before,
.verify-message__close-btn:after {
  position: absolute;
  left: 0.313rem;
  top: 0;
  content: ' ';
  height: 0.875rem;
  width: 0.094rem;
  background-color: rgba(51, 50, 56, 0.524513);
}
.verify-message__close-btn:before {
  transform: rotate(45deg);
}
.verify-message__close-btn:after {
  transform: rotate(-45deg);
}

.verify-message__description {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #4B4C59;
}

.verify-message__email {
  color: #4A90E2;
  font-weight: 600;
}

.verify-message__link {
  font-size: 0.938rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.verify-message__link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.verify-message__icon {
  display: flex;
  margin-right: 0.875rem;
  flex: 0 0 auto;
}

.verify-message__icon svg {
  width: 1.375rem;
  height: 1.375rem;
}

.notification-item {
  position: relative;
  background-color: #EBEFF5;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 20px 8px 16px 15px;
  display: flex;
  align-items: center;
}

.notification-item .notification-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #3B5A9A;
  margin-right: 5px;
  flex: 0 0 auto;
}

.notification-item .notification-image {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  flex: 0 0 auto;
  margin-right: 8px;
}

.notification-item .notification-image .notification-image__online-dot {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  background-color: #7ED321;
}

.notification-about .notification-about__title {
  font-size: 16px;
  line-height: 19px;
  color: #4B4C59;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px;
  white-space: nowrap;
}

.notification-about .notification-about__description {
  font-size: 12px;
  line-height: 14px;
  color: #4B4C59;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px;
  white-space: nowrap;
}

.notification-item .notification-button {
  padding: 0 15px;
  background-color: #4A90E2;
  box-shadow: 0px 2px 4px rgba(221, 44, 87, 0.2);
  border-radius: 2px;
  margin-top: 0;
  margin-left: auto;
  max-width: 100px;
}

.notification-close-button {
  width: 23px;
  height: 23px;
  background-color: #3B5A9A;
  position: absolute;
  right: -12px;
  top: -15px;
  border-radius: 100%;
}

.notification-close-button:before,
.notification-close-button:after {
  position: absolute;
  left: 10.5px;
  top: 4px;
  content: ' ';
  height: 15px;
  width: 1.5px;
  background-color: #EBEFF5;
}

.notification-close-button:before {
  transform: rotate(45deg);
}

.notification-close-button:after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .Toastify__toast-container {
    width: 100% !important;
  }

  .Toastify__toast-container--bottom-right {
    right: initial !important;
    bottom: 5em !important;
  }

  .Toastify__toast {
    margin-bottom: 0 !important;
  }
}

.Toastify__toast-container--bottom-right {
  right: 4em !important;
}

.Toastify__toast--default {
  background: transparent !important;
  color: inherit !important;
}

.Toastify__toast {
  box-shadow: none !important;
  overflow: visible !important;
}


.collapse-header {
  padding: 0.875rem 7.5rem 0.875rem 4rem !important;
  height: 3.75rem;
}

@media (max-width: 767px) {
  .collapse-header {
    height: auto;
    padding: 0.875rem 3rem 0.875rem 3rem !important;
  }

  .collapse-header-item__arrow {
    right: -2rem;
  }
}

.collapse-header:focus {
  outline: none !important;
}

.collapse-header-item__description {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #4B4C59;
}

.collapse-header-item__icon {
  position: absolute;
  left: -2.375rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.collapse-header-item__icon svg {
  width: 1.375rem;
  height: 1.25rem;
}

.collapse-header-item__icon-count {
  font-size: 0.938rem;
  line-height: 1.125rem;
  color: #DE60AD;
  font-weight: 600;
}

.collapse-header-item {
  position: relative;
  display: flex;
  align-items: center;
}

.collapse-header-item__link {
  text-decoration: none;
  color: #3b5a9a;
  font-size: 0.875rem;
  font-weight: 600;
}

.rc-collapse {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0.125rem !important;
  margin: 0.094rem -1.25rem 1.875rem;
  border-top: 1px solid #EDEFF4 !important;
  padding-top: 1.031rem;
}

.rc-collapse-item {
  margin: 0 1.25rem !important;
  background-color: #F5F8FD !important;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2) !important;
}

.rc-collapse-item:focus {
  outline: none !important;
}


.collapse-header-item__link:hover {
  text-decoration: underline;
}

.collapse-header-item__arrow {
  position: absolute;
  right: -5.75rem;
  top: 50%;
  transform: translateY(-50%) rotate(-180deg);
  transition: transform .2s ease;
}

.collapse-header-item__arrow svg {
  width: 1.188rem;
  height: 0.688rem;
}

@media (max-width: 767px) {
  .collapse-header-item__arrow {
    right: -2rem;
  }
}

.rc-collapse-item.rc-collapse-item-active .collapse-header-item__arrow{
  transform: translateY(-50%) rotate(0);
}

.collapse-item {
  border-top: 1px solid #EDEFF4;
}

.collapse-item__desription {
  padding: 0.875rem 7.5rem 0.875rem 4rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #4B4C59;
  background-color: #F5F8FD;
  height: 3.75rem;
}

@media (max-width: 767px) {
  .collapse-item__desription {
    height: auto;
    padding: 0.875rem 3rem 0.875rem 3rem;
  }
}

.collapse-item__link {
  text-decoration: none;
  color: #3b5a9a;
  font-size: 0.875rem;
  font-weight: 600;
}

.collapse-item__link:hover {
  text-decoration: underline;
}

.rc-collapse-content {
  padding: 0 !important;
}

.rc-collapse-content-box {
  margin: 0 !important;
}

.payment-card-wrapper {
  position: relative;
  width: 11.875rem;
  padding-top: 0.875rem;
  padding-bottom: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 1.875rem;
  margin-left: auto;
  margin-right: auto;
}

.payment-card-arrow {
  position: absolute;
  right: 0.938rem;
  top: 50%;
  height: 0.5rem;
  width: 0.5rem;
  margin-top: -0.25rem;
  border-style: solid;
  border-color: #3B5A9A;
  border-width: 0px 0.125rem 0.125rem 0px;
  transform: rotate(45deg);
}

.payment-card-wrapper .payment-card-title {
  font-size: 1.125rem;
  line-height: 1.313rem;
  letter-spacing: 0.07rem;
  color: #4B4C59;
  margin-bottom: 0.313rem;
  font-weight: 600;
}

.payment-card-wrapper .price-wrapper {
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.055rem;
  color: #4B4C59;
}

.payment-card-wrapper .price-wrapper span {
  text-decoration-line: line-through;
  color: #4B4C59;
}

.payment-card-wrapper .price-wrapper strong {
  color: #3B5A9A;
}

.payment-card-wrapper .payment-card-discount {
  position: absolute;
  left: -1.625rem;
  top: -1.125rem;
}

.payment-card-wrapper .payment-card-discount__number {
  position: absolute;
  top: 50%;
  white-space: pre-line;
  left: 50%;
  text-align: center;
  letter-spacing: 0.6px;
  line-height: 9px;
  font-size: 0.5rem;
  font-weight: bold;
  color: white;
  transform: rotate(-10.88deg) translate(-40%, -75%);
}

.payment-card-wrapper .payment-card-discount svg {
  width: 3.366rem;
  height: 3.366rem;
}

.payment-accordion {
  margin: 0 0 2.5rem !important;
  padding: 0 !important;
  border: none !important;
}

.payment-accordion .rc-collapse-content-box {
  margin-bottom: -1.563rem !important;
  padding-bottom: 0.438rem !important;
}

.payment-accordion:focus,
.payment-accordion:hover {
  outline: none !important;
}

.payment-accordion .rc-collapse-item {
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.payment-accordion .rc-collapse-item.rc-collapse-item-active .payment-card-arrow{
  transform: rotate(225deg);
  margin-top: 0;
}

.payment-accordion .collapse-header--payment {
  padding: 0 !important;
  background-color: transparent !important;
  width: 11.875rem;
  margin-left: auto;
  margin-right: auto;
  outline: none;
}

.checkbox-wrapper {
  width: 16.563rem;
  text-align: left;
  display: block;
  position: relative;
  padding-left: 1.563rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #4B4C59;
  user-select: none;
  margin-top: 1.563rem;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -0.125rem;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: #B8BFD3;
  border-radius: 0.125rem;
}

.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #B8BFD3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  left: 0.281rem;
  top: 0.125rem;
  width: 0.375rem;
  height: 0.563rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  transform: rotate(45deg);
}

.payment-cards {
  display: flex;
  align-items: center;
  margin-top: 1.875rem;
}

.payment-cards .payment-cards__item {
  margin: 0 0.125rem;
}

.payment-cards .payment-cards__item svg {
  width: 2.313rem;
  height: 1.688rem;
}

.mobile-search-btn svg{
  margin-right: -7px;
}

.mobile-search-btn.active {
  position: relative;
}

.mobile-search-btn.active:before {
  content: '';
  position: absolute;
  right: 3px;
  top: 12px;
  width: 7px;
  height: 7px;
  background-color: #DE60AD;
  border-radius: 100%;
}

.count-wrapper {
  font-size: 16px;
  line-height: 19px;
  color: #3B5A9A;
  display: flex;
  align-items: center;
  margin-left: auto;
  text-decoration: none;
}

.count-wrapper svg {
  margin-left: 4px;
}

.textarea-wrapper {
  position: relative;
  width: 100%;
}

.textarea-wrapper .count-price-wrapper {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.count-price-wrapper {
  display: flex;
  align-items: center;
}

.count-price-wrapper.mailbox {
  display: flex;
}

.count-price-wrapper.desktop {
  display: none;
}

@media (min-width: 768px) {
  .count-price-wrapper {
    display: none;
  }

  .count-price-wrapper.desktop {
    display: flex;
    padding-right: 5px;
  }
}

.count-price-wrapper .count,
.count-price-wrapper .divider {
  font-size: 14px;
  line-height: 16px;
  color: #B8BFD3;
}

.count-price-wrapper .price {
  font-size: 14px;
  line-height: 16px;
  color: #3B5A9A;
  display: flex;
  align-items: center;
}

.count-price-wrapper .price svg {
  width: 14px;
  height: 16px;
  margin-left: 2px;
}

.album-price {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.album-price svg {
  width: 18px;
  height: 20px;
}

@media (min-width: 768px) {
  .album-price {
    display: none;
  }
}

.tabs-wrapper {
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid #DADEE8;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  border-radius: 0.5rem;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.tabs-wrapper .react-tabs__tab-list {
  order: 2;
  display: flex;
  flex-direction: column;
  width: 6.25rem;
  flex: 0 0 auto;
  margin-left: auto;
  border-radius: 0.5rem;
  border: none;
  border-left: 1px solid #DADEE8;
  border-top: 1px solid #DADEE8;
  border-bottom: 1px solid #DADEE8;
  margin-top: -1px;
  margin-bottom: -1px;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.tabs-wrapper .react-tabs__tab {
  border: none;
  padding: 0.563rem 0.3rem;
  text-align: left;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #515151;
  font-weight: 600;
  display: flex;
  align-items: center;
  bottom: 0;
  border-radius: 5px;
}

.tabs-wrapper .react-tabs__tab:focus:after {
  display: none;
}

.tabs-wrapper .react-tabs__tab .top-item {
  margin-left: 0.313rem;
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.tabs-wrapper .react-tabs__tab.react-tabs__tab--selected {
  color: #3B5A9A;
}

.tabs-wrapper .react-tabs__tab-panel {
  padding: 0.75rem 0 0.75rem 1.125rem;
  display: none;
  align-items: center;
  margin-right: auto;
  width: 100%;
}

.tabs-wrapper .react-tabs__tab-panel.react-tabs__tab-panel--selected {
  display: flex;
}

.subscribe-plan-title {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3B5A9A;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.subscribe-plan-price {
  font-size: 2.125rem;
  line-height: 2.5rem;
  color: #3B5A9A;
  font-weight: 700;
  margin-bottom: 0.313rem;
}

.subscribe-plan {
  text-align: left;
}

.subscribe-plan-description {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #515151;
}

.subscribe-plan-description p {
  margin: 0 0 0.25rem;
  display: flex;
}

.subscribe-plan-description p:last-child {
  margin-bottom: 0;
}

.subscribe-plan-description svg {
  width: 0.625rem;
  height: 0.75rem;
  position: relative;
  top: auto;
  right: auto;
  margin-left: 0.313rem;
  margin-right: 0.313rem;
}

.tabs-title {
  font-size: 1.5rem;
  line-height: 1.313rem;
  text-align: center;
  color: #3B5A9A;
  margin-top: 2.5rem;
  margin-bottom: 0.938rem;
}

.tabs-subtitle {
  font-size: 0.875rem;
  line-height: 0.938rem;
  text-align: center;
  color: #4B4C59;
  margin-bottom: 1.563rem;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.profile-catalog-wrapper {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(184, 191, 211, 0.2);
  padding: 1.25rem;
  margin-top: 2rem;
  max-width: 48.575rem;
}

.profile-catalog-title {
  font-size: 1.125rem;
  line-height: 1.313rem;
  letter-spacing: 1.125px;
  color: #515151;
  margin-bottom: 1.063rem;
  font-weight: 600;
}

.profile-catalog-list {
  padding: 0;
  display: flex;
  margin: -.78125rem;
  list-style: none;
  flex-wrap: wrap;
}

.profile-catalog-photo-wrapper {
  position: relative;
}

.profile-catalog-photo-wrapper:hover .blue-button--start{
  display: block;
}

.profile-catalog-photo-wrapper a {
  text-decoration: none;
  display: block;
  position: relative;
}

.profile-catalog-photo-wrapper img{
  display: block;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;",serif;
}

@media (min-width: 768px) {
  .profile-catalog-photo-wrapper img {
    width: 14.3rem;
  }
}

.profile-catalog-list__item {
  position: relative;
  overflow: hidden;
  margin: .78125rem;
  border-radius: .125rem;
  background-color: #fff;
}

@media (max-width: 767px) {
  .profile-catalog-list__item {
    width: calc(100% / 3 - .75rem);
    margin: .375rem;
  }

  .profile-catalog-list {
    margin: -.375rem;
  }
}

@media (max-width: 590px) {
  .profile-catalog-list__item {
    width: calc(100% / 2 - .375rem);
    min-width: 8rem;
    margin: .1875rem;
  }

  .profile-catalog-list {
    margin: -.125rem;
  }
}

.profile-catalog-list__item:nth-child(4) {
  display: none;
}

.profile-catalog-status {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  max-width: 9.6875rem;
  height: 2.25rem;
  margin-left: .375rem;
  user-select: none;
  letter-spacing: .05rem;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  .profile-catalog-status {
    padding-right: 1.25rem;
  }
}

@media (max-width: 480px) {
  .profile-catalog-status {
    font-size: .75rem;
    height: 1.75rem;
  }
}

.profile-catalog-status-wrapper {
  text-decoration: none;
  display: block;
  position: relative;
}

.profile-catalog-status-online {
  font-size: 0;
  position: absolute;
  right: .8125rem;
  bottom: .8125rem;
  width: .625rem;
  height: .625rem;
  color: transparent;
  border-radius: 50%;
  background-color: #7ed321;
}

@media (max-width: 767px) {
  .profile-catalog-list__item:nth-child(4) {
    display: block;
  }

  .profile-catalog-status-online {
    right: .5625rem;
    bottom: .625rem;
    width: .5rem;
    height: .5rem;
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.mailbox-item .error-optionList {
  right: -90px !important;
}

.mailbox-item .error-optionList:before {
  right: auto !important;
  left: 8px !important;
}

.ReactModal__Body--open, .ReactModal__Html--open {
  overflow-y: visible;
}